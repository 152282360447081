import { useField } from "formik";
import { InputControl } from "formik-chakra-ui";
import React from "react";
import { DateTypeConstraints } from "src/components/Form/QuestionForm/formik";
import { BaseInputProps } from "./QuestionDynamicInputs/Question";
import { validateDate } from "src/services/formTemplate/question";

export interface DateProps extends BaseInputProps {
  constraints?: DateTypeConstraints;
}

export const DateInput: React.FC<DateProps> = ({
  id,
  isDisabled = false,
  constraints,
}) => {
  const [field] = useField({
    name: id,
    validate: (value: string) => validateDate(value, constraints),
  });

  return (
    <InputControl
      inputProps={{
        type: "date",
        isDisabled: isDisabled,
        min: constraints?.startDate ?? "1900-01-01",
        max: constraints?.endDate ?? "2200-12-31",
        value: field.value,
      }}
      id={id}
      name={field.name}
    />
  );
};
