import { Text } from "@chakra-ui/react";
import "./ContentEditable.css";

import { ContentEditable as LexicalContentEditable } from "@lexical/react/LexicalContentEditable";

type Props = {
  name?: string;
  id?: string;
  className?: string;
  placeholderClassName?: string;
  placeholder: string;
  onChange?: (e: React.ChangeEvent<any>) => void; // TODO: change `any` to type
  onBlur?: (e: React.FocusEvent<any>) => void; // TODO: change `any` to type
};

export function ContentEditable({
  className,
  placeholder,
  placeholderClassName,
  ...rest
}: Props): JSX.Element {
  return (
    <LexicalContentEditable
      className={className ?? "ContentEditable__root"}
      aria-placeholder={placeholder}
      placeholder={
        <Text
          className={placeholderClassName ?? "ContentEditable__placeholder"}
        >
          {placeholder}
        </Text>
      }
      {...rest}
    />
  );
}
