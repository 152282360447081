import { useCallback, useMemo, useState } from "react";

import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

export default function useModal(): [
  JSX.Element | null,
  (title: string, showModal: (onClose: () => void) => JSX.Element) => void
] {
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: false,
  });

  const [modalContent, setModalContent] = useState<null | {
    closeOnClickOutside: boolean;
    content: JSX.Element;
    title: string;
  }>(null);

  const modal = useMemo(() => {
    if (modalContent === null) {
      return null;
    }
    const { title, content, closeOnClickOutside } = modalContent;
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={closeOnClickOutside}
      >
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader fontSize="lg">{title}</ModalHeader>
          <ModalCloseButton />
          {content}
        </ModalContent>
      </Modal>
    );
  }, [isOpen, modalContent, onClose]);

  const showModal = useCallback(
    (
      title: string,
      // eslint-disable-next-line no-shadow
      getContent: (onClose: () => void) => JSX.Element,
      closeOnClickOutside = false
    ) => {
      setModalContent({
        closeOnClickOutside,
        content: getContent(onClose),
        title,
      });
      onOpen();
    },
    [onClose, onOpen]
  );

  return [modal, showModal];
}
