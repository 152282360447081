import { Link } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import type { OrganizationError } from "src/hooks/useOrganization";
import { formatUuid } from "src/services/id";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import type { RemoteData } from "src/types/remoteData";
import { RelationshipTableColumnType as ColumnType } from "../RelationshipsTable";

type CellFn<T extends ColumnType> = Exclude<
  ColumnDef<T>["cell"],
  undefined | string
>;

type RelationshipPersonIdColumnCellProps<T extends ColumnType> = Parameters<
  CellFn<T>
>[0] & {
  organization: RemoteData<OrganizationError, GQL.Organization>;
  personId: uuid;
  personType: GQL.person_type_enum | null;
};

function RelationshipPersonIdColumnCell<T extends ColumnType>(
  props: RelationshipPersonIdColumnCellProps<T>
) {
  const { organization, personId, personType } = props;
  const location = useLocation();
  const isViewPath = location.pathname.endsWith("/view");

  const routeType =
    personType === GQL.person_type_enum.applicant
      ? Url.OrgAdmin.Students
      : personType === GQL.person_type_enum.guardian
      ? Url.OrgAdmin.Parents
      : null;
  const url = isViewPath
    ? routeType?.view(organization, personId)
    : routeType?.edit(organization, personId);

  return (
    <Link
      as={RouterLink}
      color="primary.500"
      to={url}
      textDecoration="underline"
    >
      {formatUuid(personId)}
    </Link>
  );
}

type RelationshipPersonIdDefConfig = {
  organization: RemoteData<OrganizationError, GQL.Organization>;
};

export function buildRelationshipPersonIdColumnDef<T extends ColumnType>({
  organization,
}: RelationshipPersonIdDefConfig): ColumnDef<T> {
  return {
    accessorFn: (row) => row.person.id,
    id: "id",
    header: "id",

    cell: (props) => (
      <RelationshipPersonIdColumnCell
        {...props}
        organization={organization}
        personId={props.row.original.person.id}
        personType={props.row.original.person.person_type}
      />
    ),
  };
}
