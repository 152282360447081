import { Text, VStack } from "@chakra-ui/react";
import { StatusTag } from "src/components/DataDisplay/StatusTag";
import { ColumnDefWithTooltip } from "src/components/Table/Table";
import {
  FORM_SCHOOL_RANK_SUB_STATUS,
  FormSchoolRankSubStatusIndex,
} from "src/constants";
import type { OrganizationError } from "src/hooks/useOrganization";
import { formatIsoDateToOrgDate } from "src/services/format";
import type * as GQL from "src/types/graphql";
import type { RemoteData } from "src/types/remoteData";
import { ColumnId } from "./constants";

type SubStatusColumnDefConfig = {
  organization: RemoteData<OrganizationError, GQL.Organization>;
  header?: string;
};

type Row = {
  form_school_rank: {
    offers: { status_updated_at: timestamptz | null }[];
    waitlists: { status_updated_at: timestamptz | null }[];
    sub_status: string | null;
  } | null;
};
export function buildSubStatusColumnDef<T extends Row>({
  organization,
  header,
}: SubStatusColumnDefConfig): ColumnDefWithTooltip<T> {
  return {
    id: ColumnId.SubStatus,
    header: header ?? "sub-status",
    tooltip: "Sort by date last updated",
    cell: (props) => (
      <VStack align="left">
        {props.row.original.form_school_rank?.sub_status && (
          <StatusTag
            status={
              FORM_SCHOOL_RANK_SUB_STATUS[
                props.row.original.form_school_rank
                  .sub_status as FormSchoolRankSubStatusIndex
              ]
            }
          />
        )}

        {props.row.original.form_school_rank?.offers[0] && (
          <Text fontSize="xs">
            {formatIsoDateToOrgDate(
              props.row.original.form_school_rank.offers[0]?.status_updated_at,
              organization
            )}
          </Text>
        )}

        {props.row.original.form_school_rank?.waitlists[0] && (
          <Text fontSize="xs">
            {formatIsoDateToOrgDate(
              props.row.original.form_school_rank.waitlists[0]
                ?.status_updated_at,
              organization
            )}
          </Text>
        )}
      </VStack>
    ),
  };
}
