import {
  UseDisclosureProps,
  UseDisclosureReturn,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { z } from "zod";

type Props = { key: string } & UseDisclosureProps;
const SavedStateSchema = z.object({ isOpen: z.boolean() });
type SavedState = z.infer<typeof SavedStateSchema>;

/**
 * usePersistedDisclosure hook persists the disclosure state in localStorage.
 * When the page is reloaded, the disclosure state is restored from localStorage using the provided key.
 * NOTE: The key must be unique throughout the application to avoid state collisions.
 */
export function usePersistedDisclosure({
  key,
  ...props
}: Props): UseDisclosureReturn {
  let json: any;
  try {
    json = JSON.parse(localStorage.getItem(key) ?? "{}");
  } catch (error) {
    console.error(
      `Invalid localStorage disclosure state for key: ${key}`,
      error
    );
  }

  const savedState = SavedStateSchema.safeParse(json);
  const defaultIsOpen = savedState.success
    ? savedState.data.isOpen
    : props.defaultIsOpen;
  const disclosure = useDisclosure({
    ...props,
    defaultIsOpen,
  });

  useEffect(() => {
    localStorage.setItem(
      key,
      JSON.stringify({
        isOpen: disclosure.isOpen,
      } satisfies SavedState)
    );
  }, [disclosure.isOpen, key]);

  return disclosure;
}
