import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { ApolloError } from "src/components/Feedback/ApolloError";
import { NotFound } from "src/components/Feedback/NotFound";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { OrganizationError, useOrganization } from "src/hooks/useOrganization";
import { useUserProfile } from "src/hooks/useUserProfile";
import * as breadcrumb from "src/services/breadcrumb";
import { capitalizeFirstLetter } from "src/services/format";
import { RecentActivityFeed } from "./dashboard/RecentActivityFeed";
import { ParentProcessedForm } from "./dashboard/ParentProcessedForm";
import { AdminProcessedForm } from "./dashboard/AdminProcessedForm";

export function Home() {
  const organization = useOrganization();
  const profile = useUserProfile();

  const renderError = React.useCallback((error: OrganizationError) => {
    switch (error.kind) {
      case "NotFoundError":
        return <NotFound />;
      case "ServerError":
        return <ApolloError error={error.error} />;
    }
  }, []);

  const userFirstName = profile.hasData()
    ? capitalizeFirstLetter(profile.data.first_name ?? "admin")
    : "Avela Admin";

  return (
    <RemoteDataView remoteData={organization} error={renderError}>
      {() => (
        <Flex direction="column" gap={6}>
          <Breadcrumb items={breadcrumb.getBreadcrumbsForHome(organization)} />
          <Flex direction="column" gap={2}>
            <Heading color="gray.700" fontWeight={600}>
              Hi, {userFirstName}
            </Heading>
            <Text as="h2" color="gray.600" fontSize="lg">
              Here's what you missed
            </Text>
          </Flex>

          <Flex w="full" gap={4}>
            <Flex direction="column" flex={0.5} gap={4}>
              <Text as="h3" fontSize="lg" color="gray.800" fontWeight={600}>
                To do
              </Text>
              <Flex w="full" direction="row" gap={4}>
                <ParentProcessedForm />
                <AdminProcessedForm />
              </Flex>
            </Flex>

            <Flex direction="column" flex={0.5} gap={4}>
              <Text as="h3" fontSize="lg" color="gray.800" fontWeight={600}>
                Recent activity
              </Text>
              <RecentActivityFeed />
            </Flex>
          </Flex>
        </Flex>
      )}
    </RemoteDataView>
  );
}
