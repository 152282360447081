import { Button, Flex, Spacer, useToast } from "@chakra-ui/react";
import { debounce, uniq } from "lodash";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import priorityTemplateSchema from "src/schemas/priorityTemplate.json";
import * as breadcrumb from "src/services/breadcrumb";
import { MatchTemplates } from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import { PriorityTemplateConfig } from "src/types/priorityTemplate";
import {
  JsonEditor,
  injectSchemaEnums,
} from "../../admin/components/JsonEditor";
import { CREATE_MATCH_TEMPLATES } from "./graphql/mutations";
import {
  FETCH_MATCH_TEMPLATES,
  GET_ENROLLMENT_PERIOD_ENUMS,
} from "./graphql/queries";

const ExampleOutline: PriorityTemplateConfig = {
  enrollmentPeriodId: "[uuid]",
  templates: [
    {
      name: "[example default]",
      priorityConfig: {
        priorityGroups: [
          [
            ["tagA", "tagB"],
            ["tagA", { not: "tagC" }],
          ],
          [["tagD", "tagE"]],
        ],
        sortFields: [
          {
            field: GQL.priority_template_sort_field_enum.PriorityGroup,
            order: GQL.priority_template_sort_order_enum.asc,
          },
          {
            field: GQL.priority_template_sort_field_enum.LotteryOrder,
            order: GQL.priority_template_sort_order_enum.asc,
          },
        ],
      },
    },
  ],
};

type sort_fields_input = {
  [k: `sort_field_${number}`]:
    | GQL.priority_template_sort_field_enum
    | undefined;
  [k: `sort_order_${number}`]:
    | GQL.priority_template_sort_order_enum
    | undefined;
};

export function NewMatchTemplate() {
  const toast = useToast();
  const navigate = useNavigate();
  const organization = useOrganization();
  const [json, setJson] = React.useState(
    JSON.stringify(ExampleOutline, undefined, 2)
  );
  const [isValid, setIsValid] = React.useState(true);

  // Enrollment period ID extracted from JSON, if available.
  const [enrollmentPeriodId, setEnrollmentPeriodId] = React.useState("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parseEnrollmentPeriod = React.useCallback(
    debounce((text: string) => {
      try {
        const config = JSON.parse(text);
        setEnrollmentPeriodId(config.enrollmentPeriodId ?? "");
      } catch (error) {}
    }, 500),
    []
  );
  const handleChange = (text: string) => {
    setJson(text);
    parseEnrollmentPeriod(text);
  };

  // Enumerate available values from the database and inject them into the schema.
  const { remoteData } = useRemoteDataQuery<GQL.GetEnrollmentPeriodEnums, {}>(
    GET_ENROLLMENT_PERIOD_ENUMS
  );
  const enrollmentPeriods = useMemo(
    () => (remoteData.hasData() ? remoteData.data.enrollment_period : []),
    [remoteData]
  );
  const enrollmentPeriod = useMemo(
    () => enrollmentPeriods.find((ep) => ep.id === enrollmentPeriodId),
    [enrollmentPeriods, enrollmentPeriodId]
  );
  const schemaWithEnums = useMemo(() => {
    const validTags = enrollmentPeriod?.enrollment_period_tags ?? [];
    const validSchools = uniq(
      enrollmentPeriod?.grades.flatMap((g) => g.school_id) ?? []
    );
    return injectSchemaEnums(priorityTemplateSchema, {
      EnrollmentPeriodId: enrollmentPeriods.map((ep) => ep.id),
      SchoolId: validSchools,
      Tag: validTags.map((tag) => tag.name),
    });
  }, [enrollmentPeriods, enrollmentPeriod]);

  const [createMatchTemplate] = useRemoteDataMutation<
    GQL.CreateMatchTemplates,
    GQL.CreateMatchTemplatesVariables
  >(CREATE_MATCH_TEMPLATES);
  const uploadJSONHandler = async () => {
    // Validity is computed asynchronously, so there's a brief window (a
    // fraction of a second) in which `isValid` may be true while `json` isn't
    // actually valid.
    // TODO: Add synchronous validation of JSON.
    if (!isValid) {
      toast({
        title: "Invalid match template.",
        status: "error",
        isClosable: true,
      });
      return;
    }
    const matchTemplateConfig: PriorityTemplateConfig = JSON.parse(json);
    const { templates } = matchTemplateConfig;
    const match_templates = templates.map(
      ({ name, schools, priorityConfig }): GQL.match_template_insert_input => {
        if (schools) {
          toast({
            title: "School-specific match templates not yet supported.",
            status: "error",
            isClosable: true,
          });
          throw new Error("School-specific match templates not yet supported.");
        }
        const matchTemplate: GQL.match_template_insert_input &
          sort_fields_input = {
          name,
          organization_id: organization.toNullable()?.id ?? "",
          config: priorityConfig,
        };
        // It'd be simpler to just enumerate these, but I think this loop may be
        // less error-prone / one less thing to remember if we decide to add
        // more sort fields (which would otherwise fail silently).
        for (let i = 0; i < priorityConfig.sortFields.length; ++i) {
          matchTemplate[`sort_field_${i + 1}`] =
            priorityConfig.sortFields[i]?.field;
          matchTemplate[`sort_order_${i + 1}`] =
            priorityConfig.sortFields[i]?.order;
        }
        return matchTemplate;
      }
    );

    try {
      await createMatchTemplate({
        variables: { match_templates },
        refetchQueries: [FETCH_MATCH_TEMPLATES],
      });
      navigate(organization.map(MatchTemplates.index).withDefault("#"));
      toast({
        title: "Match templates created",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error creating match templates",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" gap={3} height="100%">
      <Breadcrumb
        items={breadcrumb.matchTemplates.getBreadcrumbsForNew(organization)}
      />
      <JsonEditor
        title="Create match template"
        schema={schemaWithEnums}
        text={json}
        onChange={handleChange}
        onChangeValidity={setIsValid}
        filename="matchTemplateConfig.json"
        height="100%"
      />
      <Flex gap={3}>
        <Spacer />
        <Button type="button" isDisabled={!isValid} onClick={uploadJSONHandler}>
          Create
        </Button>
      </Flex>
    </Flex>
  );
}
