// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3

import { type Options } from "@hey-api/client-fetch";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  clearAnswer,
  getAnswers,
  getForm,
  putAnswer,
  putAnswers,
} from "../requests/services.gen";
import {
  ClearAnswerData,
  ClearAnswerError,
  GetAnswersData,
  GetAnswersError,
  GetFormData,
  GetFormError,
  PutAnswerData,
  PutAnswerError,
  PutAnswersData,
  PutAnswersError,
} from "../requests/types.gen";
import * as Common from "./common";
export const useGetForm = <
  TData = Common.GetFormDefaultResponse,
  TError = GetFormError,
  TQueryKey extends Array<unknown> = unknown[]
>(
  clientOptions: Options<GetFormData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetFormKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getForm({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const useGetAnswers = <
  TData = Common.GetAnswersDefaultResponse,
  TError = GetAnswersError,
  TQueryKey extends Array<unknown> = unknown[]
>(
  clientOptions: Options<GetAnswersData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseGetAnswersKeyFn(clientOptions, queryKey),
    queryFn: () =>
      getAnswers({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const usePutAnswers = <
  TData = Common.PutAnswersMutationResult,
  TError = PutAnswersError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<PutAnswersData, true>, TContext>,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<PutAnswersData, true>, TContext>({
    mutationKey: Common.UsePutAnswersKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      putAnswers(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePutAnswer = <
  TData = Common.PutAnswerMutationResult,
  TError = PutAnswerError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<PutAnswerData, true>, TContext>,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<PutAnswerData, true>, TContext>({
    mutationKey: Common.UsePutAnswerKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      putAnswer(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useClearAnswer = <
  TData = Common.ClearAnswerMutationResult,
  TError = ClearAnswerError,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<ClearAnswerData, true>, TContext>,
    "mutationKey" | "mutationFn"
  >
) =>
  useMutation<TData, TError, Options<ClearAnswerData, true>, TContext>({
    mutationKey: Common.UseClearAnswerKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      clearAnswer(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
