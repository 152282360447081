import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { SelectionAlwaysOnDisplay } from "@lexical/react/LexicalSelectionAlwaysOnDisplay";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { useLexicalEditable } from "@lexical/react/useLexicalEditable";
import { useEffect, useState } from "react";
import { CAN_USE_DOM } from "./utils/canUseDOM";

import { Box } from "@chakra-ui/react";
import { LexicalEditor } from "lexical";
import { ContentEditable } from "./components";
import { useSharedHistoryContext } from "./context/SharedHistoryContext";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ContextMenuPlugin from "./plugins/ContextMenuPlugin";
import DragDropPaste from "./plugins/DragDropPastePlugin";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import ImagesPlugin from "./plugins/ImagesPlugin";
import LinkPlugin from "./plugins/LinkPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import MarkdownShortcutPlugin from "./plugins/MarkdownShortcutPlugin";
import { MaxLengthPlugin } from "./plugins/MaxLengthPlugin";
import ShortcutsPlugin from "./plugins/ShortcutsPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";

import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { SharedHistoryContext } from "./context/SharedHistoryContext";
import { ToolbarContext } from "./context/ToolbarContext";
import AllNodes from "./nodes/AllNodes";
import LexicalEditorTheme from "./themes/LexicalEditorTheme";
import { SettingsContext, useSettings } from "./context/SettingsContext";
import { SettingName, Settings } from "./context/SettingsContext/settings";
import VariablesPlugin from "./plugins/VariablesPlugin";

export type EditorRef = (editor: LexicalEditor) => void;

type Props = {
  initialValue?: string;
  name?: string;
  id?: string;
  isReadOnly?: boolean;
  placeholder?: string;
  maxLength?: number;
  width?: string;
  height?: string;
  editorRef?: EditorRef;
  onChange?: (editor: LexicalEditor) => void;
  onBlur?: (editor: LexicalEditor) => void;
  settings?: Partial<Settings>;
};

const Editor = ({ initialValue, isReadOnly, ...editorProps }: Props) => {
  const initialConfig: InitialConfigType = {
    editorState: initialValue ? JSON.parse(initialValue) : undefined,
    nodes: [...AllNodes],
    onError: (error: Error) => {
      throw error;
    },
    namespace: "LexicalEditor",
    theme: LexicalEditorTheme,
    editable: !isReadOnly,
  };

  return (
    <SettingsContext>
      <LexicalComposer initialConfig={initialConfig}>
        <SharedHistoryContext>
          <ToolbarContext>
            <Box border="1px solid" borderColor="gray.200" borderRadius="md">
              <RawEditor {...editorProps} />
            </Box>
          </ToolbarContext>
        </SharedHistoryContext>
      </LexicalComposer>
    </SettingsContext>
  );
};

const RawEditor = ({
  name,
  id,
  width,
  height,
  maxLength,
  placeholder,
  editorRef,
  onChange,
  onBlur,
  settings,
}: Props): JSX.Element => {
  const {
    setOption,
    settings: { hasLinkAttributes },
  } = useSettings();
  const { historyState } = useSharedHistoryContext();

  const isEditable = useLexicalEditable();
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false);
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    if (settings) {
      Object.entries(settings).forEach(([key, value]) => {
        setOption(key as SettingName, value);
      });
    }
  }, [settings, setOption]);

  useEffect(() => {
    if (editorRef) {
      editorRef(activeEditor);
    }
  }, [activeEditor, editorRef]);

  useEffect(() => {
    // Register an update listener to handle changes
    const unregisterUpdateListener = editor.registerUpdateListener(
      ({ editorState }) => {
        onChange?.(editor);
      }
    );

    // Cleanup the listener when the component is unmounted
    return () => {
      unregisterUpdateListener();
    };
  }, [editor, onChange]);

  const handleOnBlur = (e: React.FocusEvent<any>) => {
    onBlur?.(editor);
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener("resize", updateViewPortWidth);

    return () => {
      window.removeEventListener("resize", updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  return (
    <>
      <ToolbarPlugin
        editor={editor}
        activeEditor={activeEditor}
        setActiveEditor={setActiveEditor}
        setIsLinkEditMode={setIsLinkEditMode}
      />
      <ShortcutsPlugin
        editor={activeEditor}
        setIsLinkEditMode={setIsLinkEditMode}
      />
      <Box>
        {maxLength && <MaxLengthPlugin maxLength={maxLength} />}
        <DragDropPaste />
        <AutoFocusPlugin />
        <SelectionAlwaysOnDisplay />
        <ClearEditorPlugin />
        <AutoLinkPlugin />
        <VariablesPlugin />
        <HistoryPlugin externalHistoryState={historyState} />
        <RichTextPlugin
          contentEditable={
            <Box className="editor-scroller">
              <Box
                className="editor"
                ref={onRef}
                overflow="auto"
                width={width}
                height={height}
              >
                <ContentEditable
                  name={name}
                  id={id}
                  placeholder={placeholder || ""}
                  onBlur={handleOnBlur}
                />
              </Box>
            </Box>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <MarkdownShortcutPlugin />
        <ListPlugin />
        <CheckListPlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <ImagesPlugin />
        <LinkPlugin hasLinkAttributes={hasLinkAttributes} />
        <ClickableLinkPlugin disabled={isEditable} />
        <HorizontalRulePlugin />
        <TabIndentationPlugin />
        <ContextMenuPlugin />
        {floatingAnchorElem && !isSmallWidthViewport && (
          <>
            <FloatingLinkEditorPlugin
              anchorElem={floatingAnchorElem}
              isLinkEditMode={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
            />
            <FloatingTextFormatToolbarPlugin
              anchorElem={floatingAnchorElem}
              setIsLinkEditMode={setIsLinkEditMode}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default Editor;
