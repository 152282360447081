import { SortField } from "src/hooks/useCommonSearchParams";

import * as GQL from "src/types/graphql";

export enum RelatedFormsColumnIds {
  EnrollmentPeriod = "enrollment_period",
  FormId = "form_id",
  FormTemplateName = "form_template_name",
  FormStatus = "form_status",
}

const defaultRelatedFormsOrderBy: GQL.form_order_by = {
  form_template: { enrollment_period: { name: GQL.order_by.asc } },
};

export const buildOrderBy = (orderBy: SortField | null) => {
  if (!orderBy) {
    return [defaultRelatedFormsOrderBy];
  }

  if (orderBy.sortKey === RelatedFormsColumnIds.EnrollmentPeriod) {
    return [
      { form_template: { enrollment_period: { name: orderBy.sortType } } },
    ];
  }

  if (orderBy.sortKey === RelatedFormsColumnIds.FormTemplateName) {
    return [{ form_template: { name: orderBy.sortType } }];
  }

  if (orderBy.sortKey === RelatedFormsColumnIds.FormId) {
    return [{ id: orderBy.sortType }];
  }

  if (orderBy.sortKey === RelatedFormsColumnIds.FormStatus) {
    return [{ status: orderBy.sortType }];
  }

  return [{}];
};

type Sibling = GQL.SiblingFragment;

type PersonWithSiblings = {
  id: string;
  first_relationship: {
    second: {
      first_relationship: {
        second: Sibling;
      }[];
      second_relationship: {
        first: Sibling;
      }[];
    };
  }[];
  second_relationship: {
    first: {
      first_relationship: {
        second: Sibling;
      }[];
      second_relationship: {
        first: Sibling;
      }[];
    };
  }[];
};

// Elevated school admins needs extra logic since It's an org-admin role that will get
// all siblings of the applicant, but we only want to show siblings associated with the
// applicant's applying school or attending school
const shouldIncludeSibling = (
  isElevatedSchoolAdmin: boolean,
  applyingSchoolIds: string[],
  attendingSchoolIds: string[]
) => {
  return (sibling: Sibling) => {
    if (!isElevatedSchoolAdmin) return true;

    // For elevated school admins, check applying schools through form_school_ranks
    const hasMatchingApplyingSchool = sibling.forms?.some((form) =>
      form.form_school_ranks?.some((rank) =>
        applyingSchoolIds.includes(rank.school_id)
      )
    );

    // Check attending schools through applicant_attending_schools
    const hasMatchingAttendingSchool =
      sibling.applicant_attending_schools?.some(
        (attendance) =>
          attendance.school_id &&
          attendingSchoolIds.includes(attendance.school_id)
      );

    return hasMatchingApplyingSchool || hasMatchingAttendingSchool;
  };
};

// For elevated school admins, we also need to filter the forms they are allowed to see
const getFilterFormsFunction = (
  isElevatedSchoolAdmin: boolean,
  applyingSchoolIds: string[]
) => {
  return (sibling: Sibling) => {
    if (!isElevatedSchoolAdmin) return sibling.latest_forms;

    return sibling.latest_forms?.filter((form) =>
      form.form_school_ranks?.some((rank) =>
        applyingSchoolIds.includes(rank.school_id)
      )
    );
  };
};

// Helper function to get siblings from parent relationships
export const getSiblings = <T extends Sibling>(
  person: PersonWithSiblings,
  isElevatedSchoolAdmin: boolean,
  applyingSchoolIds: string[],
  attendingSchoolIds: string[]
): T[] => {
  const siblings = new Set<T>();
  const includeSibling = shouldIncludeSibling(
    isElevatedSchoolAdmin,
    applyingSchoolIds,
    attendingSchoolIds
  );
  const filterForms = getFilterFormsFunction(
    isElevatedSchoolAdmin,
    applyingSchoolIds
  );

  // Get siblings through first relationship (parent)
  person.first_relationship?.forEach((rel) => {
    rel.second?.first_relationship?.forEach((parentToChild) => {
      const sibling = parentToChild.second;
      if (sibling && sibling.id !== person.id && includeSibling(sibling)) {
        siblings.add({
          ...sibling,
          latest_forms: filterForms(sibling),
        } as T);
      }
    });
    rel.second?.second_relationship?.forEach((parentToChild) => {
      const sibling = parentToChild.first;
      if (sibling && sibling.id !== person.id && includeSibling(sibling)) {
        siblings.add({
          ...sibling,
          latest_forms: filterForms(sibling),
        } as T);
      }
    });
  });

  // Get siblings through second relationship (parent)
  person.second_relationship?.forEach((rel) => {
    rel.first?.first_relationship?.forEach((parentToChild) => {
      const sibling = parentToChild.second;
      if (sibling && sibling.id !== person.id && includeSibling(sibling)) {
        siblings.add({
          ...sibling,
          latest_forms: filterForms(sibling),
        } as T);
      }
    });
    rel.first?.second_relationship?.forEach((parentToChild) => {
      const sibling = parentToChild.first;
      if (sibling && sibling.id !== person.id && includeSibling(sibling)) {
        siblings.add({
          ...sibling,
          latest_forms: filterForms(sibling),
        } as T);
      }
    });
  });

  return Array.from(siblings);
};
