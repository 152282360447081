import { useField } from "formik";
import React from "react";
import {
  NUMBER_TYPE_FORMAT_WHOLE,
  NumberTypeConstraints,
} from "src/components/Form/QuestionForm/formik";
import { formatNumberWithCommas } from "src/services/format";
import { NumberInput } from "../NumberInput";
import { BaseInputProps } from "./Question";
import { validateNumber } from "src/services/formTemplate/question";

export interface NumberProps extends BaseInputProps {
  constraints?: NumberTypeConstraints;
}

export const MaskedNumberInput: React.FC<NumberProps> = ({
  id,
  isDisabled = false,
  constraints,
}) => {
  const [field, , helpers] = useField({
    name: id,
    validate: (value: number) => validateNumber(value, constraints),
  });
  const handleChange = (value: string) => {
    if (
      constraints?.format === NUMBER_TYPE_FORMAT_WHOLE ||
      !constraints?.format
    ) {
      const cleanValue = value.replace(/[^0-9]/g, "");
      value = cleanValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      // Remove any non-digit characters except for '.' (for decimals)
      value = value.replace(/[^0-9.]/g, "");
      // Split the value into integer and decimal parts
      const parts = value.split(".");

      // Format the integer part with commas (no integer size limit)
      if (parts[0]) {
        // Insert commas into the integer part
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      // If there is a decimal part, limit it to constraint.precision number of digits
      if (parts[1]) {
        parts[1] = parts[1].substring(0, constraints?.precision ?? 2);
      }

      // Join the integer and decimal parts back together
      value = parts.join(".");
    }

    helpers.setTouched(true);
    helpers.setValue(value);
  };

  const handleOnBlur = () => {
    if (constraints?.precision && field.value?.includes(".")) {
      const parts = field.value.split(".");
      if (parts[1].length < constraints.precision) {
        const paddedDecimal = parts[1].padEnd(constraints.precision, "0");
        helpers.setValue(`${parts[0]}.${paddedDecimal}`);
      }
    }

    if (
      constraints?.minValue &&
      parseFloat(field.value) < constraints.minValue
    ) {
      helpers.setValue(constraints.minValue);
      helpers.setError(
        "Value must be greater than or equal to " + constraints.minValue
      );
    }

    if (
      constraints?.maxValue &&
      parseFloat(field.value) >= constraints.maxValue
    ) {
      helpers.setValue(constraints.maxValue);
      helpers.setError("Value must be less than " + constraints.maxValue);
    }
  };

  React.useEffect(() => {
    helpers.setValue(formatNumberWithCommas(field.value, constraints));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NumberInput
      id={id}
      name={id}
      onChangeEvent={handleChange}
      label=""
      value={field.value}
      isDisabled={isDisabled}
      minValue={constraints?.minValue ?? undefined}
      maxValue={constraints?.maxValue ?? undefined}
      onKeyDown={(event) => {
        if (event.key === "." && field.value.includes(".")) {
          event.preventDefault();
        }
      }}
      onBlur={handleOnBlur}
    />
  );
};
