import { HStack, Link, Text, VStack } from "@chakra-ui/react";
import type { ColumnDef } from "@tanstack/table-core";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { StatusTag } from "src/components/DataDisplay/StatusTag";
import { FORM_STATUS } from "src/constants";
import { OrganizationError } from "src/hooks/useOrganization";
import { formatIsoDateToOrgDate } from "src/services/format";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";
import { RelationshipTableColumnType as ColumnType } from "../RelationshipsTable";

const LatestFormCell = ({
  person,
  organization,
}: {
  person: ColumnType["person"];
  organization: RemoteData<OrganizationError, GQL.Organization>;
}) => {
  const [searchParams] = useSearchParams();
  const forms = person.latest_forms;

  if (!forms || forms.length === 0) {
    return <Text variant="placeholder">No forms</Text>;
  }

  const latestForm = forms[0]!;
  const url = Url.OrgAdmin.Forms.view({
    organization,
    formTemplateId: latestForm.form_template.id,
    id: latestForm.id,
    params: searchParams.toString(),
  });

  return (
    <VStack align="left">
      <HStack>
        <Link
          as={RouterLink}
          color="primary.500"
          to={url}
          textDecoration="underline"
        >
          {latestForm.form_template.enrollment_period.name} -{" "}
          {latestForm.form_template.name}
        </Link>
      </HStack>
      <HStack>
        <StatusTag status={FORM_STATUS[latestForm.status]} />
        <Text fontSize="xs">
          {formatIsoDateToOrgDate(
            latestForm.status_updated_at || "",
            organization
          )}
        </Text>
      </HStack>
    </VStack>
  );
};

type FormInfoColumnDefConfig = {
  organization: RemoteData<OrganizationError, GQL.Organization>;
};

export function buildFormInfoColumnDef<T extends ColumnType>({
  organization,
}: FormInfoColumnDefConfig): ColumnDef<T> {
  return {
    id: "latest_form",
    header: "Latest Form",
    enableSorting: false,
    cell: ({ row }) => (
      <LatestFormCell
        person={row.original.person}
        organization={organization}
      />
    ),
  };
}
