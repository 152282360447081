import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import {
  $createTextNode,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
  LexicalNode,
  RangeSelection,
  TextNode,
} from "lexical";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as ReactDOM from "react-dom";

import { Flex, Text } from "@chakra-ui/react";
import { mergeRegister } from "@lexical/utils";
import { $createVariableNode, VariableNode } from "../nodes/VariableNode";
import {
  DefaultVariables,
  isValidVariableKey,
  Variable,
} from "../utils/variables";
const VariablesRegex = new RegExp(
  "(^|\\s|\\()([{]{2}((?:\\w*)|(?:\\w+\\.\\w*))[}]{0,2})$"
);

const ExactVariablesRegex = new RegExp(
  "(^|\\s|\\()([{]{2}((?:\\w+)|(?:\\w+\\.\\w+))[}]{2})"
);

const variablesCache = new Map();

type VariablePayload = {
  key: string;
};

export const INSERT_VARIABLE_COMMAND: LexicalCommand<VariablePayload> =
  createCommand("INSERT_VARIABLE_COMMAND");

const lookupService = {
  search(string: string, callback: (results: Array<Variable>) => void): void {
    setTimeout(() => {
      const results =
        string.length === 0
          ? DefaultVariables
          : DefaultVariables.filter((variable) =>
              variable.key.toLowerCase().includes(string.toLowerCase())
            );
      callback(results);
    }, 500);
  },
};

function useVariableLookupService(variableString: string | null) {
  const [results, setResults] = useState<Array<Variable>>([]);

  useEffect(() => {
    const cachedResults = variablesCache.get(variableString);

    if (variableString == null) {
      setResults([]);
      return;
    }

    if (cachedResults === null) {
      return;
    } else if (cachedResults !== undefined) {
      setResults(cachedResults);
      return;
    }

    variablesCache.set(variableString, null);
    lookupService.search(variableString, (newResults) => {
      variablesCache.set(variableString, newResults);
      setResults(newResults);
    });
  }, [variableString]);

  return results;
}

function checkForBraceVariables(
  text: string,
  minMatchLength: number,
  exact: boolean = false
): MenuTextMatch | null {
  const match = exact
    ? ExactVariablesRegex.exec(text)
    : VariablesRegex.exec(text);

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3] ?? "";
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + (maybeLeadingWhitespace?.length ?? 0),
        matchingString,
        replaceableString: match[2] ?? "",
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(
  text: string,
  exact: boolean = false
): MenuTextMatch | null {
  return checkForBraceVariables(text, 0, exact);
}

/**
 * Split Lexical TextNode and return a new TextNode only containing matched text.
 */
function splitNodeContainingQuery(
  anchorNode: TextNode,
  match: MenuTextMatch
): TextNode | null {
  if (!anchorNode.isSimpleText()) {
    return null;
  }

  const startOffset = match.leadOffset;
  const matchOffset = startOffset + match.replaceableString.length;

  let newNode;
  if (startOffset === 0 && matchOffset === anchorNode.getTextContent().length) {
    newNode = anchorNode;
  } else if (startOffset === 0) {
    [newNode] = anchorNode.splitText(startOffset, matchOffset);
  } else {
    [, newNode] = anchorNode.splitText(startOffset, matchOffset);
  }
  return newNode ?? null;
}

function getNodeAtSelection(selection: RangeSelection): LexicalNode | null {
  const anchor = selection.anchor;
  if (anchor.type !== "text") {
    return null;
  }
  const anchorNode = anchor.getNode();
  return anchorNode;
}

class VariableTypeaheadOption extends MenuOption {
  variable: Variable;

  constructor(variable: Variable) {
    super(variable.name);
    this.variable = variable;
  }
}

function VariablesTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: VariableTypeaheadOption;
}) {
  return (
    <Flex
      id={"typeahead-item-" + index}
      key={option.key}
      tabIndex={-1}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      direction="column"
      mt={2}
      backgroundColor={isSelected ? "gray.100" : "white"}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <Text fontSize="md">{`{{${option.variable.key}}}`}</Text>
      <Text fontSize="sm" color="blackAlpha.700">
        {option.variable.description}
      </Text>
    </Flex>
  );
}

export default function VariablesPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);
  const recentVariables: Variable[] = JSON.parse(
    localStorage.getItem("recentVariables") || "[]"
  );

  const results = useVariableLookupService(queryString);

  const options = useMemo(
    () => results.map((result) => new VariableTypeaheadOption(result)),
    [results]
  );

  const recentOptions = useMemo(
    () => recentVariables.map((result) => new VariableTypeaheadOption(result)),
    [recentVariables]
  );

  useEffect(() => {
    if (!editor.hasNodes([VariableNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand<VariablePayload>(
        INSERT_VARIABLE_COMMAND,
        (payload) => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return false;
          }
          const nodeAtSelection = getNodeAtSelection(selection);

          // if there is a node at the the selection, we need to add a space after it
          // this will allow the typeahead plugin to detect the start of the variable
          if (nodeAtSelection) {
            if (
              nodeAtSelection instanceof TextNode &&
              nodeAtSelection.isSimpleText()
            ) {
              const text = nodeAtSelection.getTextContent();
              if (!text.match(/.*\s$/)) {
                nodeAtSelection.insertAfter(new TextNode(" ")).selectEnd();
              }
            } else {
              nodeAtSelection.insertAfter(new TextNode(" ")).selectEnd();
            }
          }

          const variableNode = isValidVariableKey(payload.key)
            ? $createVariableNode(payload.key)
            : $createTextNode(payload.key);
          $insertNodes([variableNode]);
          variableNode.selectEnd();

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerNodeTransform(TextNode, (textNode: TextNode) => {
        if (textNode.isSimpleText()) {
          const match = getPossibleQueryMatch(textNode.getTextContent(), true);
          if (match) {
            const textNodesToMatch = splitNodeContainingQuery(textNode, match);
            if (textNodesToMatch) {
              textNodesToMatch.replace(
                $createVariableNode(match.matchingString)
              );
            }
          }
        }
      })
    );
  }, [editor]);

  const onSelectOption = useCallback(
    (
      selectedOption: VariableTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      const { variable } = selectedOption;
      const alreadySetAsRecent = recentVariables.find(
        (recentVariable) => recentVariable.key === variable.key
      );

      if (!alreadySetAsRecent && recentVariables.length >= 3) {
        recentVariables.unshift(variable);
        recentVariables.pop();
        localStorage.setItem(
          "recentVariables",
          JSON.stringify(recentVariables)
        );
      } else if (!alreadySetAsRecent) {
        localStorage.setItem(
          "recentVariables",
          JSON.stringify([...recentVariables, variable])
        );
      }

      editor.update(() => {
        const variableNode = $createVariableNode(variable.key);
        if (nodeToReplace) {
          nodeToReplace.replace(variableNode);
        }
        variableNode.select();
        closeMenu();
      });
    },
    [editor, recentVariables]
  );

  const checkForVariableMatch = useCallback((text: string) => {
    return getPossibleQueryMatch(text) ?? null;
  }, []);

  return (
    <LexicalTypeaheadMenuPlugin<VariableTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForVariableMatch}
      options={[...recentOptions, ...options]}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) =>
        anchorElementRef && anchorElementRef.current && results.length
          ? ReactDOM.createPortal(
              <Flex
                direction="column"
                width="350px"
                maxH="380px"
                p={0}
                borderRadius="sm"
                border="1px solid"
                borderColor="blackAlpha.600"
                background="white"
                overflow="auto"
              >
                {recentOptions.length > 0 && (
                  <Flex
                    direction="column"
                    borderBottom={`1px solid`}
                    p={5}
                    gap={2}
                    borderColor="gray.200"
                  >
                    <Text
                      fontSize="sm"
                      color="blackAlpha.800"
                      fontWeight="semibold"
                    >
                      Recent variables
                    </Text>

                    {recentOptions.map((option, i: number) => (
                      <VariablesTypeaheadMenuItem
                        index={i}
                        isSelected={selectedIndex === i}
                        onClick={() => {
                          setHighlightedIndex(i);
                          selectOptionAndCleanUp(option);
                        }}
                        onMouseEnter={() => {
                          setHighlightedIndex(i);
                        }}
                        key={option.key}
                        option={option}
                      />
                    ))}
                  </Flex>
                )}
                <Flex direction="column" p={4} gap={2}>
                  <Text
                    fontSize="sm"
                    color="blackAlpha.800"
                    fontWeight="semibold"
                  >
                    All variables
                  </Text>
                  {options.map((option, i: number) => (
                    <VariablesTypeaheadMenuItem
                      index={recentOptions.length + i}
                      isSelected={selectedIndex === recentOptions.length + i}
                      onClick={() => {
                        setHighlightedIndex(recentOptions.length + i);
                        selectOptionAndCleanUp(option);
                      }}
                      onMouseEnter={() => {
                        setHighlightedIndex(recentOptions.length + i);
                      }}
                      key={option.key}
                      option={option}
                    />
                  ))}
                </Flex>
              </Flex>,
              anchorElementRef.current
            )
          : null
      }
    />
  );
}
