import { useMemo } from "react";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_FEED_DATA } from "./graphql/queries";
import * as GQL from "src/types/graphql";
import { GenericError } from "src/components/Feedback/GenericError";
import { Table } from "src/components/Table/Table";
import { ColumnDef } from "@tanstack/react-table";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";
import { isNotNull } from "src/services/predicates";
import { FeedEvent, resolveFeedTransaction } from "./helpers";
import { Flex, Skeleton } from "@chakra-ui/react";
import { FeedItem } from "./components/FeedItem";

export function RecentActivityFeed() {
  // TODO: Replace with the enrollment period select value
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();
  const organization = useOrganization();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetFeedData,
    GQL.GetFeedDataVariables
  >(GET_FEED_DATA, {
    variables: {
      enrollment_period_id: selectedEnrollmentPeriod?.id ?? "",
      organization_id: organization.hasData() ? organization.data.id : "",
    },
    skip: !selectedEnrollmentPeriod?.id || !organization.hasData(),
  });

  const columns: ColumnDef<FeedEvent<string>>[] = useMemo(
    () => [
      {
        id: "entry",
        cell: (props) => <FeedItem event={props.row.original} />,
      },
    ],
    []
  );

  return (
    <>
      {remoteData.isLoading() ? (
        <Flex direction="column" gap="4" alignItems="center" mt={2}>
          <Skeleton height="2rem" width="100%" borderRadius="md" />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={0.9} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.1} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.2} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.3} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.4} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.5} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.6} />
          <Skeleton height="2rem" width="100%" borderRadius="md" speed={1.7} />
        </Flex>
      ) : (
        <RemoteDataView remoteData={remoteData} error={() => <GenericError />}>
          {({
            audit_form_transaction,
            school,
            grade,
            enrollment_period_tag,
          }) => (
            <Flex h={475}>
              <Table<FeedEvent<string>>
                data={audit_form_transaction
                  .flatMap((transaction) =>
                    resolveFeedTransaction(
                      transaction,
                      school,
                      grade,
                      enrollment_period_tag
                    )
                  )
                  .filter(isNotNull)}
                columns={columns}
                hideHeader={true}
                tableHeader={() => <></>}
                isLoading={remoteData.isLoading()}
              />
            </Flex>
          )}
        </RemoteDataView>
      )}
    </>
  );
}
