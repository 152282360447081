import { Flex, Portal } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { FormTemplateOutletContext } from "../../Edit";
import {
  GET_GRADES_CONFIG_BY_ORGANIZATION,
  GET_QUESTION_TYPES_BY_ORGANIZATION
} from "../../graphql/queries";
import { EditFormTemplateBackButton } from "../components/BackButton";
import { EditFormTemplateProvider } from "./context";
import { FormButtons } from "./FormButtons";
import { Tab } from "./Tab";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { AccessDenied } from "src/components/Feedback/AccessDenied";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";
import { FormTemplateStatuses } from "src/services/url/OrgAdmin";
import { useOrganization } from "src/hooks/useOrganization";

export const ContentTab: React.FC<{}> = () => {
  const {
    organizationId,
    formTemplate,
    verificationOptions,
    actionBarRef: submitButtonRef
  } = useOutletContext<FormTemplateOutletContext>();
  const navigate = useNavigate();
  const organization = useOrganization();
  const { enrollmentPeriodId = "" } = useParams();

  const flags = useFlags([
    "form-builder-read-only",
    "form-builder-orgadmin-edit"
  ]);
  const isFormBuilderReadOnlyEnabled = flags["form-builder-read-only"].enabled;
  const isFormBuilderFullEditEnabled =
    flags["form-builder-orgadmin-edit"].enabled;
  const isOrgAdmin = useRequiredHasuraRoles([HasuraRole.ORG_ADMIN]);

  const { remoteData: gradesConfigData } = useRemoteDataQuery<
    GQL.GetGradesConfigByOrganization,
    GQL.GetGradesConfigByOrganizationVariables
  >(GET_GRADES_CONFIG_BY_ORGANIZATION, {
    variables: {
      organizationId: organizationId
    },
    fetchPolicy: "network-only",
    skip: !organizationId
  });

  const { remoteData: questionTypesRemoteData } = useRemoteDataQuery<
    GQL.GetQuestionTypesByOrganization,
    GQL.GetQuestionTypesByOrganizationVariables
  >(GET_QUESTION_TYPES_BY_ORGANIZATION, {
    variables: {
      organizationId: organizationId
    },
    fetchPolicy: "network-only",
    skip: !organizationId
  });

  const accessDeniedRedirectRoute = organization
    .map((org) =>
      FormTemplateStatuses.edit(org, enrollmentPeriodId, formTemplate.id)
    )
    .withDefault("#");

  if (
    isOrgAdmin &&
    !isFormBuilderReadOnlyEnabled &&
    !isFormBuilderFullEditEnabled
  ) {
    setTimeout(() => {
      navigate(accessDeniedRedirectRoute);
    }, 2000);
    return (
      <AccessDenied message="You don't have access to this page, redirecting..." />
    );
  }

  return (
    <EditFormTemplateProvider
      formTemplate={formTemplate}
      customQuestionTypes={
        questionTypesRemoteData.hasData()
          ? questionTypesRemoteData.data.custom_question_type
          : []
      }
    >
      <Tab
        organizationId={organizationId}
        verificationOptions={verificationOptions}
        gradesConfig={
          gradesConfigData.hasData() ? gradesConfigData.data.grade_config : []
        }
      />
      <Portal containerRef={submitButtonRef}>
        <Flex justifyContent="space-between">
          <EditFormTemplateBackButton />
          <FormButtons formTemplate={formTemplate} />
        </Flex>
      </Portal>
    </EditFormTemplateProvider>
  );
};
