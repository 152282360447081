import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useFlags } from "flagsmith/react";
import { Field, FieldProps, Form, Formik } from "formik";
import _ from "lodash";
import React from "react";
import { RiEditLine } from "react-icons/ri";
import { CustomFormInput } from "src/components/Inputs/CustomFormInput";
import { Card } from "src/components/Layout/Card";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import { validateWithZod } from "src/services/formValidations";
import * as AF from "src/types/formTemplate";
import { HasuraRole } from "src/types/hasuraRole";
import { z } from "zod";

type Mode = "View" | "Edit";
type Props = {
  original: AF.DisclaimerSection<AF.WithId>;
  draft: Draft.Disclaimer | undefined;
  onUpdate?: (disclaimer: Draft.Disclaimer) => void;
};
export const DisclaimerForm: React.FC<Props> = ({
  original,
  draft,
  onUpdate,
}) => {
  const [mode, setMode] = React.useState<Mode>("View");
  const onEditButtonClick = React.useCallback(() => {
    setMode("Edit");
  }, []);
  const onCancelButtonClick = React.useCallback(() => {
    setMode("View");
  }, []);

  const onUpdateButtonClick = React.useCallback(
    (updatedDisclaimer: Draft.Disclaimer) => {
      if (onUpdate) onUpdate(updatedDisclaimer);
      setMode("View");
    },
    [onUpdate]
  );

  const children = React.useMemo(() => {
    switch (mode) {
      case "Edit":
        return (
          <DisclaimerFormEdit
            disclaimer={draft ?? Draft.fromOriginalDisclaimer(original)}
            onCancelButtonClick={onCancelButtonClick}
            onUpdate={onUpdateButtonClick}
          />
        );

      case "View":
        return (
          <DisclaimerFormView
            disclaimer={draft ?? original}
            onEditButtonClick={onEditButtonClick}
          />
        );

      default:
        const _exhaustiveCheck: never = mode;
        return _exhaustiveCheck;
    }
  }, [
    original,
    draft,
    mode,
    onCancelButtonClick,
    onEditButtonClick,
    onUpdateButtonClick,
  ]);

  const hasChange = React.useMemo(() => {
    return draft !== undefined && !_.isEqual(draft, original);
  }, [draft, original]);

  return (
    <Card display="flex" flexDirection="column" showBorder gap="3">
      {hasChange ? (
        <Alert status="warning" rounded="md">
          <AlertIcon />
          <AlertDescription>
            This disclaimer has changes that haven't been published and are not
            visible to parents.
          </AlertDescription>
        </Alert>
      ) : null}
      {children}
    </Card>
  );
};

type ViewProps = {
  disclaimer: AF.DisclaimerSection<AF.WithId>;
  onEditButtonClick: () => void;
};
export const DisclaimerFormView: React.FC<ViewProps> = ({
  disclaimer,
  onEditButtonClick,
}) => {
  const flags = useFlags(["form-builder-orgadmin-edit"]);
  const isFormBuilderFullEditEnabled =
    flags["form-builder-orgadmin-edit"].enabled;
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);

  return (
    <Flex direction="column" gap="3">
      <Flex justifyContent="flex-end" alignItems="center">
        <WithRequiredHasuraRoles
          roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
        >
          {(isAvelaAdmin || isFormBuilderFullEditEnabled) && (
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<RiEditLine />}
              flexShrink={0}
              onClick={onEditButtonClick}
            >
              Edit disclaimer
            </Button>
          )}
        </WithRequiredHasuraRoles>
      </Flex>
      <Text fontWeight="bold">Disclaimer Text</Text>
      <Text fontSize="sm" whiteSpace="pre-wrap">
        {disclaimer.disclaimer}
      </Text>
    </Flex>
  );
};

const FormSchema = z.object({
  disclaimer: z.string().min(1, "This field is required"),
});
type FormType = z.infer<typeof FormSchema>;
type EditProps = {
  disclaimer: Draft.Disclaimer;
  onCancelButtonClick: () => void;
  onUpdate: (question: Draft.Disclaimer) => void;
};
export const DisclaimerFormEdit: React.FC<EditProps> = ({
  disclaimer,
  onCancelButtonClick,
  onUpdate,
}) => {
  const initialValues = {
    disclaimer: disclaimer.disclaimer,
  };
  const onSubmit = React.useCallback(
    (values: FormType) => {
      onUpdate({ ...disclaimer, disclaimer: values.disclaimer });
    },
    [disclaimer, onUpdate]
  );
  return (
    <Formik<FormType>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateWithZod(FormSchema)}
    >
      {(formik) => (
        <Flex as={Form} direction="column" gap="3">
          <Field name="disclaimer">
            {({ field, meta }: FieldProps) => (
              <CustomFormInput label="Disclaimer Text" error={meta.error}>
                <Textarea height="20rem" {...field} />
              </CustomFormInput>
            )}
          </Field>
          <Flex gap="3" justifyContent="right" paddingY="3">
            <Button
              variant="ghost"
              colorScheme="gray"
              onClick={onCancelButtonClick}
            >
              Cancel
            </Button>
            <Button type="submit">Update disclaimer</Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};
