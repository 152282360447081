import { Organization } from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { ORGANIZATION } from "./constants";

function template(
  entity: string,
  organization?: Organization,
  action?: string,
  params?: string
): string {
  const orgPart = organization?.path ?? ORGANIZATION;
  const actionPart = action ?? "";
  const paramsPart = params ? `?${params}` : "";

  return entity
    ? `/${orgPart}/admin/${entity}${actionPart}${paramsPart}`
    : `/${orgPart}/admin${actionPart}${paramsPart}`;
}
export const index = (organization: Organization): string =>
  template("", organization);
export const indexPath = template("");

export const Enrollments = {
  index: (organization: Organization): string =>
    template("enrollments", organization),
  new: (organization: Organization): string =>
    template("enrollments", organization, "/new"),
  edit: (organization: Organization, id: string) =>
    template("enrollments", organization, `/${id}/edit`),
  indexPath: template("enrollments"),
  newPath: template("enrollments", undefined, "/new"),
  editPath: template("enrollments", undefined, "/:enrollmentPeriodId/edit"),
};

export const ENROLLMENT_PERIOD_GENERAL_PATH = "general";
export const EnrollmentPeriodGeneral = {
  edit: (organization: Organization, enrollmentPeriodId: uuid): string => {
    return [
      Enrollments.edit(organization, enrollmentPeriodId),
      ENROLLMENT_PERIOD_GENERAL_PATH,
    ].join("/");
  },
  editPath: [Enrollments.editPath, ENROLLMENT_PERIOD_GENERAL_PATH].join("/"),
};

export const ENROLLMENT_PERIOD_SCHOOLS_PATH = "schools";
export const EnrollmentPeriodSchools = {
  edit: (organization: Organization, enrollmentPeriodId: uuid): string => {
    return [
      Enrollments.edit(organization, enrollmentPeriodId),
      ENROLLMENT_PERIOD_SCHOOLS_PATH,
    ].join("/");
  },
  editPath: [Enrollments.editPath, ENROLLMENT_PERIOD_SCHOOLS_PATH].join("/"),
};

export const ENROLLMENT_PERIOD_FORM_TEMPLATES_PATH = "form-templates";
export const EnrollmentPeriodFormTemplates = {
  index: (organization: Organization, enrollmentPeriodId: uuid): string => {
    return [
      Enrollments.edit(organization, enrollmentPeriodId),
      ENROLLMENT_PERIOD_FORM_TEMPLATES_PATH,
    ].join("/");
  },
  edit: (
    organization: Organization,
    enrollmentPeriodId: uuid,
    formTemplateId: uuid
  ): string => {
    return [
      Enrollments.edit(organization, enrollmentPeriodId),
      ENROLLMENT_PERIOD_FORM_TEMPLATES_PATH,
      formTemplateId,
    ].join("/");
  },
  new: (organization: Organization, enrollmentPeriodId: uuid): string => {
    return [
      Enrollments.edit(organization, enrollmentPeriodId),
      ENROLLMENT_PERIOD_FORM_TEMPLATES_PATH,
      "new",
    ].join("/");
  },
  indexPath: [Enrollments.editPath, ENROLLMENT_PERIOD_FORM_TEMPLATES_PATH].join(
    "/"
  ),
  editPath: [
    Enrollments.editPath,
    ENROLLMENT_PERIOD_FORM_TEMPLATES_PATH,
    ":formTemplateId",
  ].join("/"),
  newPath: [
    Enrollments.editPath,
    ENROLLMENT_PERIOD_FORM_TEMPLATES_PATH,
    "new",
  ].join("/"),
};

export const FORM_TEMPLATES_CONTENT_PATH = "content";
export const FormTemplateContent = {
  edit: (
    organization: Organization,
    enrollmentPeriodId: uuid,
    formTemplateId: uuid
  ): string => {
    return [
      EnrollmentPeriodFormTemplates.edit(
        organization,
        enrollmentPeriodId,
        formTemplateId
      ),
      FORM_TEMPLATES_CONTENT_PATH,
    ].join("/");
  },
  editPath: [
    EnrollmentPeriodFormTemplates.editPath,
    FORM_TEMPLATES_CONTENT_PATH,
  ].join("/"),
};

export const FORM_TEMPLATES_STATUSES_PATH = "statuses";
export const FormTemplateStatuses = {
  edit: (
    organization: Organization,
    enrollmentPeriodId: uuid,
    formTemplateId: uuid
  ): string => {
    return [
      EnrollmentPeriodFormTemplates.edit(
        organization,
        enrollmentPeriodId,
        formTemplateId
      ),
      FORM_TEMPLATES_STATUSES_PATH,
    ].join("/");
  },
  editPath: [
    EnrollmentPeriodFormTemplates.editPath,
    FORM_TEMPLATES_STATUSES_PATH,
  ].join("/"),
};

export const FORM_TEMPLATES_SETTINGS_PATH = "settings";
export const FormTemplateSettings = {
  edit: (
    organization: Organization,
    enrollmentPeriodId: uuid,
    formTemplateId: uuid
  ): string => {
    return [
      EnrollmentPeriodFormTemplates.edit(
        organization,
        enrollmentPeriodId,
        formTemplateId
      ),
      FORM_TEMPLATES_SETTINGS_PATH,
    ].join("/");
  },
  editPath: [
    EnrollmentPeriodFormTemplates.editPath,
    FORM_TEMPLATES_SETTINGS_PATH,
  ].join("/"),
};

/**
 *  React Router v6 expects relative paths for nested Route path prop:
 *  - <Route path="/"
 */
export const MESSAGE_TEMPLATES_PATH = "message-templates";
export const MessageTemplate = {
  index: (
    organization: Organization,
    enrollmentPeriodId: uuid,
    formTemplateId: uuid
  ): string => {
    return [
      EnrollmentPeriodFormTemplates.edit(
        organization,
        enrollmentPeriodId,
        formTemplateId
      ),
      MESSAGE_TEMPLATES_PATH,
    ].join("/");
  },
  indexPath: [
    EnrollmentPeriodFormTemplates.editPath,
    MESSAGE_TEMPLATES_PATH,
  ].join("/"),
  edit: (
    organization: Organization,
    enrollmentPeriodId: uuid,
    formTemplateId: string,
    messageTemplateType: string,
    isViewOnly: boolean
  ): string => {
    return [
      EnrollmentPeriodFormTemplates.edit(
        organization,
        enrollmentPeriodId,
        formTemplateId
      ),
      MESSAGE_TEMPLATES_PATH,
      messageTemplateType,
      "edit",
      isViewOnly,
    ].join("/");
  },
  editPath: [
    EnrollmentPeriodFormTemplates.editPath,
    MESSAGE_TEMPLATES_PATH,
    ":messageTemplateType",
    "edit",
    ":isViewOnly",
  ].join("/"),
};

export const Schools = {
  index: (organization: Organization): string =>
    template("schools", organization),
  new: (organization: Organization): string =>
    template("schools", organization, "/new"),
  edit: (organization: Organization, id: string): string =>
    template("schools", organization, `/${id}/edit`),
  indexPath: template("schools"),
  newPath: template("schools", undefined, "/new"),
  editPath: template("schools", undefined, "/:id/edit"),
};

export const Students = {
  index: (organization: Organization): string =>
    template("students", organization),
  new: (organization: Organization): string =>
    template("students", organization, "/new"),
  edit: (
    organization: Organization | RD.RemoteData<unknown, Organization>,
    id: string
  ): string => {
    const getUrl = (org: Organization) =>
      template("students", org, `/${id}/edit`);
    return RD.isRemoteData(organization)
      ? organization.map(getUrl).withDefault("#")
      : getUrl(organization);
  },
  view: (
    organization: Organization | RD.RemoteData<unknown, Organization>,
    id: string
  ): string => {
    const getUrl = (org: Organization) =>
      template("students", org, `/${id}/view`);
    return RD.isRemoteData(organization)
      ? organization.map(getUrl).withDefault("#")
      : getUrl(organization);
  },
  indexPath: template("students"),
  newPath: template("students", undefined, "/new"),
  editPath: template("students", undefined, "/:id/edit"),
  viewPath: template("students", undefined, "/:id/view"),
};

export const Parents = {
  index: (organization: Organization): string =>
    template("parents", organization),
  new: (organization: Organization): string =>
    template("parents", organization, "/new"),
  edit: (
    organization: Organization | RD.RemoteData<unknown, Organization>,
    id: string
  ): string => {
    const getUrl = (org: Organization) =>
      template("parents", org, `/${id}/edit`);
    return RD.isRemoteData(organization)
      ? organization.map(getUrl).withDefault("#")
      : getUrl(organization);
  },
  view: (
    organization: Organization | RD.RemoteData<unknown, Organization>,
    id: string
  ): string => {
    const getUrl = (org: Organization) =>
      template("parents", org, `/${id}/view`);
    return RD.isRemoteData(organization)
      ? organization.map(getUrl).withDefault("#")
      : getUrl(organization);
  },
  indexPath: template("parents"),
  newPath: template("parents", undefined, "/new"),
  editPath: template("parents", undefined, "/:id/edit"),
  viewPath: template("parents", undefined, "/:id/view"),
};

export const Forms = {
  index: ({
    organization,
    formTemplateId,
    params,
  }: {
    organization: Organization;
    formTemplateId: uuid;
    params?: string;
  }): string => template("forms", organization, `/${formTemplateId}`, params),
  new: ({
    organization,
    formTemplateId,
    params,
  }: {
    organization: Organization;
    formTemplateId: uuid;
    params: string;
  }): string =>
    template("forms", organization, `/${formTemplateId}/new`, params),
  edit: ({
    organization,
    id,
    formTemplateId,
    params,
  }: {
    organization: Organization;
    id: string;
    formTemplateId: uuid;
    params: string;
  }): string =>
    template("forms", organization, `/${formTemplateId}/${id}/edit`, params),
  view: ({
    organization,
    formTemplateId,
    id,
    params,
  }: {
    organization: RD.RemoteData<unknown, Organization>;
    formTemplateId: string;
    id: string;
    params?: string;
  }): string =>
    organization
      .map((org: Organization) =>
        template("forms", org, `/${formTemplateId}/${id}/view`, params)
      )
      .withDefault("#"),
  verifications: ({
    organization,
    formTemplateId,
    params,
  }: {
    organization: Organization;
    formTemplateId: uuid;
    params: string;
  }): string =>
    template("forms", organization, `/${formTemplateId}/verify`, params),
  export: ({
    organization,
    formTemplateId,
    params,
  }: {
    organization: Organization;
    formTemplateId: uuid;
    params: string;
  }): string =>
    template("forms", organization, `/${formTemplateId}/export`, params),
  newMessage: ({
    organization,
    formTemplateId,
    params,
  }: {
    organization: Organization;
    formTemplateId?: string;
    params?: string;
  }): string =>
    formTemplateId
      ? template(
          "forms",
          organization,
          `/${formTemplateId}/message/new`,
          params
        )
      : Messages.new(organization, params),
  indexPath: template("forms", undefined, "/:formTemplateId"),
  newPath: template("forms", undefined, "/:formTemplateId/new"),
  editPath: template("forms", undefined, "/:formTemplateId/:id/edit"),
  viewPath: template("forms", undefined, "/:formTemplateId/:id/view"),
  newMessagePath: template("forms", undefined, "/:formTemplateId/message/new"),
  verificationsPath: template("forms", undefined, `/:formTemplateId/verify`),
  exportPath: template("forms", undefined, "/:formTemplateId/export"),
};

export const FormImport = {
  view: (
    organization: Organization,
    formTemplateId: uuid,
    params?: string
  ): string =>
    template("forms", organization, `/${formTemplateId}/import`, params),
  viewPath: template("forms", undefined, "/:formTemplateId/import"),
};

export const Team = {
  index: (organization: Organization): string => template("team", organization),
  newTeam: (organization: Organization): string =>
    template("team", organization, "/new"),
  editTeam: (organization: Organization, id: string): string =>
    template("team", organization, `/${id}/edit`),
  newMember: (organization: Organization): string =>
    template("team", organization, "/member/new"),
  editMember: (organization: Organization, id: string): string =>
    template("team", organization, `/member/${id}/edit`),
  indexPath: template("team"),
  newTeamPath: template("team", undefined, "/new"),
  editTeamPath: template("team", undefined, "/:id/edit"),
  newMemberPath: template("team", undefined, "/member/new"),
  editMemberPath: template("team", undefined, "/member/:id/edit"),
};

export const DataServices = {
  index: (organization: Organization): string =>
    template("data_services", organization),
  indexPath: template("data_services"),
};

export const ApiCredentials = {
  new: (organization: Organization): string =>
    template("api-credential", organization, "/new"),
  newPath: template("api-credential", undefined, "/new"),
};

export const Webhooks = {
  new: (organization: Organization): string =>
    template("webhooks", organization, "/new"),
  edit: (organization: Organization, pk: string): string =>
    template("webhooks", organization, `/${pk}/edit`),
  newPath: template("webhooks", undefined, "/new"),
  editPath: template("webhooks", undefined, "/:pk/edit"),
};

export const SCHEDULED_EXPORTS_PATH = "scheduled-exports";
export const ScheduledExports = {
  view: (organization: Organization): string =>
    template(SCHEDULED_EXPORTS_PATH, organization, "/view"),
  viewPath: template(SCHEDULED_EXPORTS_PATH, undefined, "view"),
};

export const Messages = {
  index: (organization: Organization): string =>
    template("messages", organization),
  new: (organization: Organization, params?: string): string =>
    template("messages", organization, "/new", params),
  view: (
    organization: RD.RemoteData<unknown, Organization>,
    id: string
  ): string =>
    organization
      .map((org: Organization) => template("messages", org, `/${id}/view`))
      .withDefault("#"),
  indexPath: template("messages", undefined),
  newPath: template("messages", undefined, "/new"),
  viewPath: template("messages", undefined, "/:id/view"),
};

export const CapacitiesConfig = {
  index: (organization: Organization): string => {
    return template("capacities", organization);
  },
  indexPath: template("capacities"),
};

export const MATCH_TEMPLATES_PATH = "match-templates";
export const MatchTemplates = {
  index: (organization: Organization): string =>
    template(MATCH_TEMPLATES_PATH, organization),
  new: (organization: Organization): string =>
    template(MATCH_TEMPLATES_PATH, organization, "/new"),
  indexPath: template(MATCH_TEMPLATES_PATH, undefined),
  newPath: template(MATCH_TEMPLATES_PATH, undefined, "/new"),
};

export const GlossaryPath = {
  index: (organization: Organization, pathAlias: string): string =>
    template(pathAlias, organization),
  new: (organization: Organization, pathAlias: string): string =>
    template(pathAlias, organization, "/new"),
  edit: (organization: Organization, id: string, pathAlias: string): string =>
    template(pathAlias, organization, `/${id}/edit`),
  indexPath: template(":glossaryType"),
  newPath: template(":glossaryType", undefined, "/new"),
  editPath: template(":glossaryType", undefined, "/:id/edit"),
};
