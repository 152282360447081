import { Flex, Text } from "@chakra-ui/react";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import { EventType, HistoryEvent } from "src/services/auditLog";
import { formatIsoDateToOrgDateTime } from "src/services/format";
import * as GQL from "src/types/graphql";

interface HistoryLogEntryProps {
  event: HistoryEvent<string>;
}
export const HistoryLogEntry = ({ event }: HistoryLogEntryProps) => {
  const organization = useOrganization();

  let message = undefined;

  switch (event.type) {
    case EventType.AddedTo:
    case EventType.RemovedFrom:
      message = (
        <>
          <Text as="span" fontWeight="semibold">
            {event.value}
          </Text>{" "}
          {event.type}{" "}
          <Text as="span" fontWeight="semibold">
            <Glossary>{event.field}</Glossary>
          </Text>
        </>
      );
      break;
    default:
      message = (
        <>
          <Text as="span" fontWeight="semibold">
            <Glossary>
              {event.field === "Form" ? "Form response" : event.field}
            </Glossary>
          </Text>{" "}
          {event.type}{" "}
          <Text as="span" fontWeight="semibold">
            {event.value}
          </Text>
        </>
      );
      break;
  }
  return (
    <Flex>
      <Text whiteSpace="normal">
        {message}
        <Text as="span" color="blackAlpha.700">
          {" by "}
        </Text>
        {event.author
          ? getPersonTypeLabel(event.author.type) + " " + event.author.full_name
          : // Org admins can't see user info of Avela admins, so we assume the author is Avela admin.
            "Avela admin"}
        {". "}
        <Text as="span" color="blackAlpha.700" fontSize="xs">
          {event.isoTimestamp &&
            formatIsoDateToOrgDateTime(event.isoTimestamp, organization)}
        </Text>
      </Text>
    </Flex>
  );
};

function getPersonTypeLabel(type: GQL.person_type_enum | null): string | null {
  switch (type) {
    case GQL.person_type_enum.admin:
      return "Avela admin";
    case GQL.person_type_enum.orgAdmin:
    case GQL.person_type_enum.districtAdmin:
    case GQL.person_type_enum.schoolAdmin:
      return "admin";
    case GQL.person_type_enum.applicant:
    case GQL.person_type_enum.guardian:
    case null:
      return "";
  }
}
