import { Button } from "@chakra-ui/react";
import { ReactNode } from "react";

export const TbToggle = ({
  children,
  onClick,
  isDisabled,
  isActive,
  title,
  "aria-label": ariaLabel,
}: {
  children: ReactNode;
  isDisabled?: boolean;
  onClick: () => void;
  isActive: boolean;
  title?: string;
  "aria-label"?: string;
}): JSX.Element => {
  return (
    <Button
      variant={isActive ? "solid" : "ghost"}
      colorScheme="gray"
      aria-pressed={isActive}
      isDisabled={isDisabled}
      onClick={onClick}
      title={title}
      aria-label={ariaLabel || title}
      size="sm"
      padding="0"
    >
      {children}
    </Button>
  );
};
