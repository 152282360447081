export type Variable = {
  name: string;
  key: string;
  description: string;
};

// After unifying with new text editor, use only this component,
// remove src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/AutomaticMessages/Edit/constants.ts
export const DefaultVariables: Variable[] = [
  {
    name: "Organization name",
    key: "organization.name",
    description: "Inserts organization name",
  },
  {
    name: "Organization path",
    key: "organization.path",
    description: "Inserts organization path",
  },
  {
    name: "Parent full name",
    key: "parent.name",
    description: "Inserts the name of the recipient Parent",
  },
];

export function isValidVariableKey(key: string): boolean {
  return DefaultVariables.some(
    (variable) => variable.key === key || `{{${variable.key}}}` === key
  );
}
