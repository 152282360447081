// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3

import { type Options } from "@hey-api/client-fetch";
import { UseQueryResult } from "@tanstack/react-query";
import {
  clearAnswer,
  getAnswers,
  getForm,
  putAnswer,
  putAnswers,
} from "../requests/services.gen";
export type GetFormDefaultResponse = Awaited<
  ReturnType<typeof getForm>
>["data"];
export type GetFormQueryResult<
  TData = GetFormDefaultResponse,
  TError = unknown
> = UseQueryResult<TData, TError>;
export const useGetFormKey = "GetForm";
export const UseGetFormKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useGetFormKey, ...(queryKey ?? [clientOptions])];
export type GetAnswersDefaultResponse = Awaited<
  ReturnType<typeof getAnswers>
>["data"];
export type GetAnswersQueryResult<
  TData = GetAnswersDefaultResponse,
  TError = unknown
> = UseQueryResult<TData, TError>;
export const useGetAnswersKey = "GetAnswers";
export const UseGetAnswersKeyFn = (
  clientOptions: Options<unknown, true>,
  queryKey?: Array<unknown>
) => [useGetAnswersKey, ...(queryKey ?? [clientOptions])];
export type PutAnswersMutationResult = Awaited<ReturnType<typeof putAnswers>>;
export const usePutAnswersKey = "PutAnswers";
export const UsePutAnswersKeyFn = (mutationKey?: Array<unknown>) => [
  usePutAnswersKey,
  ...(mutationKey ?? []),
];
export type PutAnswerMutationResult = Awaited<ReturnType<typeof putAnswer>>;
export const usePutAnswerKey = "PutAnswer";
export const UsePutAnswerKeyFn = (mutationKey?: Array<unknown>) => [
  usePutAnswerKey,
  ...(mutationKey ?? []),
];
export type ClearAnswerMutationResult = Awaited<ReturnType<typeof clearAnswer>>;
export const useClearAnswerKey = "ClearAnswer";
export const UseClearAnswerKeyFn = (mutationKey?: Array<unknown>) => [
  useClearAnswerKey,
  ...(mutationKey ?? []),
];
