import { InfoIcon } from "@chakra-ui/icons";
import { Flex, Heading, Icon, Tooltip } from "@chakra-ui/react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useFlags } from "flagsmith/react";
import { useMemo } from "react";
import { RelationshipPerson, RelationshipTypes } from "src/constants";
import { useOrganization } from "src/hooks/useOrganization";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { capitalizeFirstLetter } from "src/services/format";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { AddRelationshipButton } from "../Buttons/AddRelationshipButton";
import { DeleteRelationshipButton } from "../Buttons/DeleteRelationshipButton";
import { WithRequiredHasuraRoles } from "../Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "../Permissions/WithUserPermissions";
import { Table } from "./Table";
import { buildFormInfoColumnDef } from "./columns/LatestFormColumn";
import { buildRelationshipPersonIdColumnDef } from "./columns/RelationshipPersonIdColumn";

export type RelationshipTableColumnType = {
  id: string;
  person: RelationshipPerson;
};

type ColumnType = RelationshipTableColumnType;

interface RelationshipsTableProps {
  personId: string | undefined;
  tableData: ColumnType[];
  entityType: GQL.person_type_enum;
  relationshipType?: RelationshipTypes;
  readOnly?: boolean;
}

export const RelationshipsTable = ({
  tableData,
  personId,
  entityType,
  relationshipType,
  readOnly = false,
}: RelationshipsTableProps) => {
  const organization = useOrganization();
  const userPermissions = useUserPermissions();
  const flags = useFlags(["parent-profile-forms-preview"]);

  const columns: ColumnDef<ColumnType>[] = useMemo(
    () => [
      buildRelationshipPersonIdColumnDef<ColumnType>({
        organization,
      }),
      {
        accessorKey: "first_name",
        header: "first name",
        accessorFn: (row) => row?.person.first_name || "",
      },
      {
        accessorKey: "middle_name",
        header: "middle name",
        accessorFn: (row) => row?.person.middle_name || "",
      },
      {
        accessorKey: "last_name",
        header: "last name",
        accessorFn: (row) => row?.person.last_name || "",
      },
      ...((relationshipType === RelationshipTypes.Student ||
        relationshipType === RelationshipTypes.Sibling) &&
      flags["parent-profile-forms-preview"].enabled
        ? [
            buildFormInfoColumnDef<ColumnType>({
              organization,
            }),
          ]
        : []),
      ...(userPermissions.hasSome(["user:update"]) && !readOnly
        ? [
            {
              id: "Actions",
              cell: ({ row }: { row: Row<ColumnType> }) => {
                const id: string = row.original.id;
                return (
                  <WithRequiredHasuraRoles
                    roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
                  >
                    <WithUserPermissions permissions={["user:update"]}>
                      <DeleteRelationshipButton id={id} />
                    </WithUserPermissions>
                  </WithRequiredHasuraRoles>
                );
              },
            },
          ]
        : []),
    ],
    [organization, relationshipType, flags, userPermissions, readOnly]
  );

  return (
    <Flex direction="column" gap={2}>
      <Flex justifyContent="space-between">
        <Heading as="h2" fontSize="2xl" fontWeight="400">
          {relationshipType
            ? `${capitalizeFirstLetter(relationshipType)}s`
            : "Relationships"}
          {relationshipType === RelationshipTypes.Sibling && (
            <>
              {" "}
              <Tooltip label="Other applicants that share at least one of the same parent/guardian">
                <Icon as={InfoIcon} color="gray.500" cursor="help" />
              </Tooltip>
            </>
          )}
        </Heading>
        {personId && !readOnly && (
          <AddRelationshipButton id={personId} entityType={entityType} />
        )}
      </Flex>
      <Table
        data={tableData}
        columns={columns}
        noDataMessage={`This user has no ${relationshipType}s`}
      />
    </Flex>
  );
};
