import { Flex } from "@chakra-ui/layout";
import { FlexProps } from "@chakra-ui/react";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";
import { AddressForm } from "./AddressForm";
import { NameForm } from "./NameForm";

export const ParentForm: React.FC<FlexProps & { isDisabled?: boolean }> = ({
  isDisabled = false,
  ...props
}) => {
  const isAllowedToEditRefId = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
  ]);
  return (
    <Flex direction="column" gap={2} {...props}>
      <NameForm
        hide={["birth_date"]}
        firstName={{ label: "Parent/guardian first name" }}
        middleName={{ label: "Parent/guardian middle name" }}
        lastName={{ label: "Parent/guardian last name" }}
        isDisabled={isDisabled}
      />
      <InputWithValidationControl
        isRequired={false}
        inputProps={{ placeholder: "E.g.: 12345678" }}
        name="reference_id"
        id="reference_id"
        label={"Reference ID (Optional)"}
        isDisabled={!isAllowedToEditRefId || isDisabled}
      />
      <AddressForm isDisabled={isDisabled} />
    </Flex>
  );
};
