import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button as ChakraButton,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import { ReactElement, ReactNode } from "react";

export function DropDownItem({
  icon,
  onClick,
  title,
  shortcut,
  isActive = false,
}: {
  icon?: React.ReactElement;
  title?: string;
  shortcut?: string;
  isActive?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <MenuItem
      title={title}
      onClick={onClick}
      gap={4}
      as={Flex}
      justifyContent="space-between"
      background={isActive ? "gray.100" : undefined}
    >
      <HStack gap={3} verticalAlign="center">
        {icon}
        <Text fontSize="xs" textColor="gray.700">
          {title}
        </Text>
      </HStack>
      {shortcut && (
        <Text fontSize="xs" textColor="gray.400">
          {shortcut}
        </Text>
      )}
    </MenuItem>
  );
}

export function DropDown({
  width,
  maxWidth,
  isDisabled = false,
  icon,
  label,
  "aria-label": ariaLabel,
  children,
}: {
  width?: string;
  maxWidth?: string;
  isDisabled?: boolean;
  icon?: ReactElement;
  "aria-label"?: string;
  label?: string;
  children: ReactNode;
}): JSX.Element {
  return (
    <Menu>
      <MenuButton
        as={ChakraButton}
        leftIcon={icon}
        iconSpacing="1"
        rightIcon={<ChevronDownIcon />}
        aria-label={ariaLabel}
        isDisabled={isDisabled}
        variant="ghost"
        colorScheme="gray"
        size="md"
        padding={2}
        width={width}
        maxWidth={maxWidth}
        height="8"
        overflow="hidden"
        textOverflow="clip"
        whiteSpace="nowrap"
      >
        <Text fontSize="xs">{label}</Text>
      </MenuButton>
      <MenuList>{children}</MenuList>
    </Menu>
  );
}
