import type { Spread } from "lexical";

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from "lexical";
import { isValidVariableKey } from "../utils/variables";

export type SerializedVariableNode = Spread<
  {
    variableKey: string;
    type: "variable";
    version: 1;
  },
  SerializedTextNode
>;

function convertVariableElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createVariableNode(textContent);
    return {
      node,
    };
  }

  return null;
}

const variableStyle = "background-color: rgba(24, 119, 232, 0.2)";
const invalidVariableStyle = "background-color: rgba(255, 0, 0, 0.3)";

export class VariableNode extends TextNode {
  __variable: string;
  __invalidVariable: boolean;

  static getType(): string {
    return "variable";
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(node.__variable, node.__text, node.__key);
  }
  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    const node = $createVariableNode(serializedNode.variableKey);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(variableKey: string, text?: string, key?: NodeKey) {
    super(text ?? `{{${variableKey}}}`, key);
    this.__variable = variableKey;
    this.__invalidVariable = !isValidVariableKey(variableKey);
  }

  exportJSON(): SerializedVariableNode {
    return {
      ...super.exportJSON(),
      variableKey: this.__variable,
      type: "variable",
      version: 1,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.style.cssText = this.__invalidVariable
      ? invalidVariableStyle
      : variableStyle;
    dom.className = "variable";
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-variable", "true");
    element.textContent = this.__text;
    return { element };
  }

  isSegmented(): false {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-variable")) {
          return null;
        }
        return {
          conversion: convertVariableElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity(): true {
    return true;
  }

  isToken(): true {
    return true;
  }
}

export function $createVariableNode(variableKey: string): VariableNode {
  const variableNode = new VariableNode(variableKey);
  variableNode.setMode("segmented").toggleDirectionless();
  return variableNode;
}

export function $isVariableNode(
  node: LexicalNode | null | undefined
): node is VariableNode {
  return node instanceof VariableNode;
}
