import { groupBy, sortBy } from "lodash";
import { FORM_SCHOOL_RANK_SUB_STATUS, FORM_STATUS } from "src/constants";
import * as GQL from "src/types/graphql";
import * as BackwardCompatibility from "src/services/backwardCompatibility";
import { isNotNull } from "src/services/predicates";

export function resolveFeedTransaction(
  transaction: GQL.GetFeedData_audit_form_transaction,
  schools: Pick<GQL.SimpleSchoolFragment, "id" | "name">[],
  grades: Pick<GQL.GetFormHistory_grade, "id" | "program">[],
  tags: Pick<GQL.GetFormHistory_enrollment_period_tag, "id" | "name">[]
): FeedEvent<string>[] {
  const rowEvents = transaction.logged_actions;
  const author = rowEvents[0]?.user?.people[0] ?? null;

  return Array.from(
    resolveChanges(rowEvents, schools, grades, tags),
    (change) => ({
      ...change,
      author: author && {
        // API driven changes don't have a person info.
        full_name: author.full_name ?? "API",
        // TODO: Fall back to `x-hasura-role`?
        type: author.person_type ?? null,
      },
      isoTimestamp: transaction.action_tstamp_tx,
    })
  );
}

// Shorthand for long type name.
type RowEvent = GQL.GetFeedData_audit_form_transaction_logged_actions;

export enum FeedEventType {
  Updated = "updated",
  Implied = "",
  AddedTo = "added",
  RemovedFrom = "removed",
  Created = "created",
  Submitted = "Submitted",
  Cleared = "cleared",
}

type Change<T> = {
  field: string;
  type: FeedEventType;
  value: T;
};

export type FeedEvent<T> = Change<T> & {
  author: {
    full_name: string | null;
    type: GQL.person_type_enum | null;
  } | null;
  isoTimestamp: string | null;
};

function* resolveChanges(
  rowEvents: RowEvent[],
  schools: Pick<GQL.SimpleSchoolFragment, "id" | "name">[],
  grades: Pick<GQL.GetFormHistory_grade, "id" | "program">[],
  tags: Pick<GQL.GetFormHistory_enrollment_period_tag, "id" | "name">[]
): Generator<Change<string>> {
  const {
    form_events,
    form_school_rank_events,
    form_verification_result_events,
    waitlist_events,
    offer_events,
    form_school_offer_status_history_events,
    form_school_tag_events,
    form_tag_events,
  } = BackwardCompatibility.renameProperties(
    groupBy(
      rowEvents,
      (e) => BackwardCompatibility.tableName(e.table_name) + "_events"
    )
  );

  // Verifies if the event is a transition from NotConsidered to another status.
  // If so, it shouldn't appear as a cleared status log.
  const isNotConsideredTransition = (
    event: GQL.GetFormHistory_audit_form_transaction_logged_actions
  ): boolean => {
    if (!form_school_rank_events) return false;

    for (const transitionEvent of form_school_rank_events) {
      if (
        transitionEvent.action === "U" &&
        transitionEvent.changed_fields?.status ===
          GQL.form_school_rank_status_enum.NotConsidered
      ) {
        const { form_id: eventFormId, school_id: eventSchoolId } =
          event.row_data;
        const {
          form_id: notConsideredFormId,
          school_id: notConsideredSchoolId,
        } = transitionEvent.row_data;

        if (
          eventFormId === notConsideredFormId &&
          eventSchoolId === notConsideredSchoolId
        ) {
          return true;
        }
      }
    }

    return false;
  };

  if (form_school_rank_events) {
    // show INSERT and UPDATE as EventType.Updated
    // show DELETE as EventType.Deleted
    // filter any UPDATE with lottery_order (this is not ranking changes)

    const valueOf = (row: any): CorrelatedValue => {
      return {
        value: row.school_id,
        sortKey: row.rank,
      };
    };

    const labelOf = (
      corrValue: CorrelatedValue,
      list: GQL.GetFormHistory_audit_form_transaction_logged_actions[]
    ) => {
      const found = list.find(
        (entry) => entry.row_data.school_id === corrValue.value
      );

      if (!found) {
        return null;
      }

      const rank = found.changed_fields?.rank ?? found.row_data.rank;

      return {
        value: `${rank + 1}. ${
          schools.find((s) => s.id === found.row_data.school_id)?.name
        }`,
        sortKey: rank,
      };
    };

    const {
      I: inserted = [],
      D: deleted = [],
      U: updated = [],
    } = groupBy(form_school_rank_events, (e) => e.action);

    const notConsideredStatusChangeEntries = updated.filter(
      (e) =>
        e.changed_fields?.status ===
        GQL.form_school_rank_status_enum.NotConsidered
    );

    for (const notConsideredEntry of notConsideredStatusChangeEntries) {
      updated.splice(updated.indexOf(notConsideredEntry));
      const school_name =
        schools.find((s) => s.id === notConsideredEntry.row_data?.school_id)
          ?.name ?? "ranked school";

      yield {
        field: `${school_name} status`,
        type: FeedEventType.Updated,
        value:
          FORM_SCHOOL_RANK_SUB_STATUS[
            GQL.form_school_rank_status_enum.NotConsidered
          ]?.label ?? GQL.form_school_rank_status_enum.NotConsidered,
      };
    }

    const clearedStatusEntries = updated.filter(
      (e) => e.changed_fields?.status === null
    );
    for (const clearedStatusEntry of clearedStatusEntries) {
      updated.splice(updated.indexOf(clearedStatusEntry));

      const school_name =
        schools.find((s) => s.id === clearedStatusEntry.row_data?.school_id)
          ?.name ?? "ranked school";
      yield {
        field: `${school_name} status`,
        type: FeedEventType.Cleared,
        value: "",
      };
    }

    const clearedOfferAndWaitlistEntries = updated.filter(
      (e) => e.changed_fields && "rank" in e.changed_fields
    );

    const updatedEntries = [
      ...inserted,
      ...clearedOfferAndWaitlistEntries.filter(
        (e) =>
          e.changed_fields?.lottery_order === null ||
          e.changed_fields?.lottery_order === undefined
      ),
    ];

    const correlatedList = correlateInsertsWithDeletes(
      deleted,
      updatedEntries,
      valueOf
    );

    const correlatedDeletes = correlatedList
      .filter((e) => e.wasPresentBefore && !e.wasPresentAfter)
      .map((e) => labelOf(e, deleted))
      .filter(isNotNull);

    const correlatedUpdates = correlatedList
      .filter((e) => e.wasPresentAfter)
      .map((e) => labelOf(e, updatedEntries))
      .filter(isNotNull);

    if (correlatedDeletes.length > 0) {
      yield {
        field: "School ranking",
        type: FeedEventType.RemovedFrom,
        value: sortBy(correlatedDeletes, (x) => x.sortKey)
          .map((x) => x.value)
          .join(", "),
      };
    }

    if (correlatedUpdates.length > 0) {
      yield {
        field: "School ranking",
        type: FeedEventType.Updated,
        value: sortBy(correlatedUpdates, (x) => x.sortKey)
          .map((x) => x.value)
          .join(", "),
      };
    }
  }

  if (form_verification_result_events) {
    for (const [, verificationResultEvents] of Object.entries(
      groupBy(
        form_verification_result_events,
        (e) => e.row_data.form_verification_id
      )
    )) {
      const typeAndValue = interpretTableChange(
        verificationResultEvents,
        (row) => ({
          value: row.verification_status ?? "Pending",
          sortKey: row.verification_status ?? "Pending",
        })
      );
      if (typeAndValue) {
        yield {
          field: "verification",
          type: FeedEventType.Updated,
          value: typeAndValue.value,
        };
      }
    }
  }

  if (form_events) {
    for (const formEvent of form_events) {
      if (formEvent.row_data) {
        continue;
      }

      if (formEvent.action === "I") {
        yield {
          field: "Form",
          type: FeedEventType.Created,
          value: "",
        };
      } else if (formEvent.changed_fields?.status) {
        const status = formEvent.changed_fields.status;
        yield {
          field: "Form status",
          type: FeedEventType.Updated,
          value: FORM_STATUS[status as GQL.form_status_enum]?.label ?? status,
        };
      }
    }
  }

  if (form_school_offer_status_history_events) {
    for (const schoolSubmittedEvent of form_school_offer_status_history_events) {
      if (schoolSubmittedEvent.row_data) {
        continue;
      }

      if (
        (schoolSubmittedEvent.action === "I" &&
          schoolSubmittedEvent.row_data?.submitted_at) ||
        schoolSubmittedEvent.changed_fields?.submitted_at
      ) {
        yield {
          field: "",
          type: FeedEventType.Submitted,
          value:
            schools.find(
              (s) => s.id === schoolSubmittedEvent.row_data.school_id
            )?.name ?? "ranked school",
        };
      }
    }
  }

  if (offer_events) {
    for (const offerEvent of offer_events) {
      if (offerEvent.row_data) {
        continue;
      }
      const school_name =
        schools.find((s) => s.id === offerEvent.row_data?.school_id)?.name ??
        "";
      const gradeId =
        offerEvent.changed_fields?.grade_id ?? offerEvent.row_data.grade_id;
      const program = grades.find((g) => g.id === gradeId)?.program?.label;
      if (
        offerEvent.action === "I" ||
        (offerEvent.action === "U" && offerEvent.changed_fields?.status)
      ) {
        const status = (
          offerEvent.action === "U"
            ? offerEvent.changed_fields?.status
            : offerEvent.row_data.status
        ) as GQL.offer_status_enum;
        const offerStatusValue =
          FORM_SCHOOL_RANK_SUB_STATUS[status]?.label ?? status;
        yield {
          field: `${school_name} status`,
          type: FeedEventType.Updated,
          value:
            offerStatusValue +
            // TODO: Make the "to" not bold.
            (program ? ` to ${program}` : ""),
        };
      }

      if (offerEvent.action === "D" && !isNotConsideredTransition(offerEvent)) {
        yield {
          field: `${school_name} status`,
          type: FeedEventType.Cleared,
          value: "",
        };
      }
    }
  }

  if (waitlist_events) {
    // Group by school to hide redundant waitlist events.
    const waitlistEventsBySchool = groupBy(
      waitlist_events.map((e) => e.row_data.school_id)
    );

    for (const waitlistEvents of Object.values(waitlistEventsBySchool)) {
      // If multiple waitlist events are in the same transaction for a single
      // school, then we can assume they all represent the same type of event,
      // so we can just look at the first one.
      const waitlistEvent = waitlistEvents[0];
      if (!waitlistEvent) {
        continue;
      }

      const school_name =
        schools.find((s) => s.id === waitlistEvent.row_data.school_id)?.name ??
        "";
      if (
        waitlistEvent.action === "I" ||
        (waitlistEvent.action === "U" && waitlistEvent.changed_fields?.status)
      ) {
        const status = (
          waitlistEvent.action === "U"
            ? waitlistEvent.changed_fields?.status
            : waitlistEvent.row_data.status
        ) as GQL.waitlist_status_enum;
        const waitlistStatusValue =
          FORM_SCHOOL_RANK_SUB_STATUS[status]?.label ?? status;
        yield {
          field: `${school_name} status`,
          type: FeedEventType.Updated,
          value: waitlistStatusValue,
        };
      }

      const isWaitlistToOfferTransition = (): boolean => {
        if (!offer_events) return false;

        for (const transitionEvent of offer_events) {
          if (transitionEvent.action === "I") {
            const {
              form_id: waitlistFormId,
              school_id: waitlistSchoolId,
              grade_id: waitlistGradeId,
            } = waitlistEvent.row_data;
            const {
              form_id: offerFormId,
              school_id: offerSchoolId,
              grade_id: offerGradeId,
            } = transitionEvent.row_data;

            if (
              waitlistFormId === offerFormId &&
              waitlistSchoolId === offerSchoolId &&
              waitlistGradeId === offerGradeId
            ) {
              return true;
            }
          }
        }

        return false;
      };

      if (
        waitlistEvent.action === "D" &&
        !isWaitlistToOfferTransition() &&
        !isNotConsideredTransition(waitlistEvent)
      ) {
        yield {
          field: `${school_name} status`,
          type: FeedEventType.Cleared,
          value: "",
        };
      }
    }
  }

  if (form_school_tag_events) {
    for (const tagEvent of form_school_tag_events) {
      const schoolName =
        schools.find((s) => s.id === tagEvent.row_data?.school_id)?.name ?? "";
      const tag =
        tags.find((t) => t.id === tagEvent.row_data?.tag_id)?.name ?? "";
      let value = "";
      let eventType = FeedEventType.Implied;
      switch (tagEvent.action) {
        case "I": {
          // Tag [tag name] added to [school name] by [user role] [user name].
          value = schoolName;
          eventType = FeedEventType.AddedTo;
          break;
        }
        case "D": {
          value = schoolName;
          eventType = FeedEventType.RemovedFrom;
          break;
        }
        default: {
          // do nothing
          break;
        }
      }

      if (value) {
        yield {
          field: value,
          value: `Tag ${tag}`,
          type: eventType,
        };
      }
    }
  }

  if (form_tag_events) {
    for (const tagEvent of form_tag_events) {
      const tag =
        tags.find((t) => t.id === tagEvent.row_data?.tag_id)?.name ?? "";
      let eventType = FeedEventType.Implied;
      switch (tagEvent.action) {
        case "I": {
          // Tag [tag name] added to form by [user role] [user name].
          eventType = FeedEventType.AddedTo;
          break;
        }
        case "D": {
          eventType = FeedEventType.RemovedFrom;
          break;
        }
        default: {
          // do nothing
          break;
        }
      }

      yield {
        field: "form",
        value: `Tag ${tag}`,
        type: eventType,
      };
    }
  }
}

type DisplayValue = {
  value: string;
  sortKey: number | string;
};

type CorrelatedValue = DisplayValue & {
  wasPresentBefore?: boolean;
  wasPresentAfter?: boolean;
};

// Pairs up insertion events with deletion events by the values they represent.
function correlateInsertsWithDeletes(
  deleted: RowEvent[],
  inserted: RowEvent[],
  valueOf: (row_data: jsonb) => DisplayValue
) {
  const entries: CorrelatedValue[] = deleted.map(({ row_data }) => ({
    ...valueOf(row_data),
    wasPresentBefore: true,
  }));
  for (const insertEvent of inserted) {
    const insertedValue = valueOf(insertEvent.row_data);
    const entry = entries.find((e) => e.value === insertedValue.value);
    if (entry) {
      entry.wasPresentAfter = true;
    } else {
      entries.push({
        ...insertedValue,
        wasPresentAfter: true,
      });
    }
  }
  return entries;
}

// Interprets a collection of insert, delete, and update events as a single
// change, or returns null if no meaningful changes were actually made.
function interpretTableChange(
  rowEvents: RowEvent[],
  valueOf: (row_data: jsonb) => DisplayValue
) {
  const {
    I: inserted,
    D: deleted,
    U: updated,
  } = groupBy(rowEvents, (e) => e.action);
  if (updated) {
    const changes = updated.filter(
      (e) =>
        valueOf(e.row_data).value !==
        valueOf({ ...e.row_data, ...e.changed_fields }).value
    );
    if (changes.length === 0) return null;
    // TODO: Treat null/empty strings as added/removed instead of updated.
    return {
      type: FeedEventType.Updated,
      value: sortBy(
        changes.map((e) => valueOf({ ...e.row_data, ...e.changed_fields })),
        (x) => x.sortKey
      )
        .map((x) => x.value)
        .join(", "),
    };
  } else if (inserted || deleted) {
    const correlated = correlateInsertsWithDeletes(
      deleted ?? [],
      inserted ?? [],
      valueOf
    );
    if (correlated.every((v) => v.wasPresentBefore === v.wasPresentAfter)) {
      return null;
    }
    return {
      type: inserted
        ? deleted
          ? FeedEventType.Updated
          : FeedEventType.AddedTo
        : FeedEventType.RemovedFrom,
      value: sortBy(
        correlated.filter((value) =>
          inserted ? value.wasPresentAfter : value.wasPresentBefore
        ),
        (x) => x.sortKey
      )
        .map((x) => x.value)
        .join(", "),
    };
  }
  // There were no inserts, deletes, or updates.
  return null;
}
