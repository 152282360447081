const VERTICAL_GAP = 12;

export function setFloatingElemPositionForLinkEditor(
  targetRect: DOMRect | null,
  floatingElem: HTMLElement,
  anchorElem: HTMLElement
): void {
  if (targetRect === null) {
    floatingElem.style.opacity = "0";
    floatingElem.style.transform = "translate(-10000px, -10000px)";
    return;
  }

  const anchorElemRect = anchorElem.getBoundingClientRect();

  const visibleRect = {
    top: anchorElem.scrollTop,
    left: anchorElem.scrollLeft,
    right: anchorElem.scrollLeft + anchorElemRect.width,
    bottom: anchorElem.scrollTop + anchorElemRect.height,
  };

  const adjustedRect = {
    top: targetRect.y - anchorElemRect.top + anchorElem.scrollTop,
    left: targetRect.x - anchorElemRect.left + anchorElem.scrollLeft,
    width: targetRect.width,
    height: targetRect.height,
  };

  const { top, left } = setFloatingElemWithinVisibleRect(
    adjustedRect,
    floatingElem,
    visibleRect
  );

  floatingElem.style.opacity = "1";
  floatingElem.style.transform = `translate(${left}px, ${top}px)`;
}

const setFloatingElemWithinVisibleRect = (
  targetRect: { top: number; left: number; width: number; height: number },
  floatingElem: HTMLElement,
  visibleRect: { top: number; left: number; right: number; bottom: number }
) => {
  const floatingElemRect = floatingElem.getBoundingClientRect();

  let top = targetRect.top;
  let left = targetRect.left;

  // Adjust for top overflow
  if (top < visibleRect.top) {
    top = targetRect.top;
  }

  // Adjust for bottom overflow
  if (top + floatingElemRect.height > visibleRect.bottom) {
    top -= targetRect.height + floatingElemRect.height + VERTICAL_GAP * 2;
  }

  // Adjust for left overflow
  if (left < visibleRect.left) {
    left = visibleRect.left;
  }

  // Adjust for right overflow
  if (left + floatingElemRect.width > visibleRect.right) {
    left = visibleRect.right - targetRect.width - floatingElemRect.width;
  }

  return { top, left };
};
