import { MESSAGE_CONTENT_LIMIT } from "src/constants";
import { z } from "zod";

export const FormSchema = z.object({
  emailSubject: z.string().min(1, "This field is required"),
  emailMarkup: z.string().min(1, "This field is required"),
  emailText: z.string().min(1, "This field is required"),
  smsBody: z
    .string()
    .min(1, "This field is required")
    .superRefine((val, ctx) => {
      if (val.length > MESSAGE_CONTENT_LIMIT) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Limit reached ${MESSAGE_CONTENT_LIMIT - val.length}`
        });
      }
    })
});
export type FormType = z.infer<typeof FormSchema>;
