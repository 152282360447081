import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { useRemoteDataMutation } from "./useRemoteDataMutation";
import { UPSERT_FORM_ANSWERS } from "src/scenes/orgAdmin/forms/graphql/mutations";
import * as GQL from "src/types/graphql";
import { useQueryClient } from "@tanstack/react-query";
import {
  usePutAnswers,
  useGetAnswersKey,
} from "src/generated/hooks/form-service/queries";
import { PutAnswersData } from "src/generated/hooks/form-service/requests";
import { ApolloError, DocumentNode, FetchResult } from "@apollo/client";
import React from "react";
import * as RD from "src/types/remoteData";
import { AnswerBankMapping } from "src/services/formTemplate/answer";

type UpsertFormAnswerVariables = GQL.UpsertFormAnswerVariables;
type UpsertFormAnswer = GQL.UpsertFormAnswer;

/*
 * This hook is used to upsert form answers.
 * It uses the form-service API to upsert form answers.
 * If the form-service is enabled, it uses the form-service API to upsert form answers.
 * Otherwise, it uses the GraphQL API to upsert form answers.
 */
export function useFormAnswerUpsert() {
  const flags = useFlags(["form-service"]);
  const isFormServiceEnabled = flags["form-service"].enabled;
  const queryClient = useQueryClient();

  const [upsertFormAnswersGQL, upsertFormAnswersGQLStatus] =
    useRemoteDataMutation<UpsertFormAnswer, UpsertFormAnswerVariables>(
      UPSERT_FORM_ANSWERS
    );

  const {
    mutateAsync: upsertFormAnswersREST,
    isPending: isUpsertFormAnswersRESTPending,
  } = usePutAnswers(undefined, {
    onSuccess: (data) => {
      if ("error" in data) throw new Error(data.error?.error.message);
    },
  });

  const upsertFormAnswers = async (
    variables: UpsertFormAnswerVariables,
    additionalVariables?: {
      answerBankMappingIds: AnswerBankMapping[];
    },
    options?: {
      refetchQueries?: DocumentNode[];
      awaitRefetchQueries?: boolean;
    }
  ): Promise<FetchResult<UpsertFormAnswer>> => {
    if (!isFormServiceEnabled) {
      return upsertFormAnswersGQL({
        variables,
        refetchQueries: options?.refetchQueries,
        awaitRefetchQueries: options?.awaitRefetchQueries,
      });
    }

    // Convert GraphQL variables to REST format
    const restPayload: PutAnswersData = {
      path: {
        formId: variables.form_id,
      },
      body: {
        data: {},
      },
    };

    // Process standard answers
    variables.answers.forEach((answer) => {
      if (!answer.question_id) return;

      if (
        answer.free_text_answer !== null &&
        answer.free_text_answer !== undefined
      ) {
        restPayload.body.data[answer.question_id] = answer.free_text_answer;
      } else if (
        answer.number_answer !== null &&
        answer.number_answer !== undefined
      ) {
        restPayload.body.data[answer.question_id] = answer.number_answer;
      } else if (
        answer.date_answer !== null &&
        answer.date_answer !== undefined
      ) {
        restPayload.body.data[answer.question_id] = answer.date_answer;
      } else if (Array.isArray(answer.form_answer_options?.data)) {
        // Handle single/multi select answers
        const optionIds = answer.form_answer_options?.data
          .map((opt) => opt.form_question_option_id)
          .filter((id): id is string => id != null);
        restPayload.body.data[answer.question_id] = optionIds ?? [];
      }
    });

    // Process grade answers
    variables.grades_answers.forEach((answer) => {
      if (answer.question_id && answer.grade_config_id) {
        restPayload.body.data[answer.question_id] = answer.grade_config_id;
      }
    });

    // Process address answers
    variables.address_answers.forEach((answer) => {
      if (answer.question_id) {
        const addressData = {
          street_address: answer.street_address || "",
          street_address_line_2: answer.street_address_line_2 || "",
          city: answer.city || "",
          state: answer.state || "",
          zip_code: answer.zip_code || "",
        };
        restPayload.body.data[answer.question_id] = addressData;
      }
    });

    // Process custom question answers
    variables.custom_question_answers.forEach((answer) => {
      if (answer.question_id) {
        const answerBankMapping =
          additionalVariables?.answerBankMappingIds.find(
            (ab) => ab.questionId === answer.question_id
          );
        const answerBankId = answerBankMapping?.answerBankId;
        const referenceId = answerBankMapping?.referenceId;
        const answersByQuestionId: {
          [key: string]: string | Array<string> | number | null;
        } = {};
        const customQuestionAnswer = answer.form_answer;
        if (!customQuestionAnswer?.data.question_id) return;
        const questionId = customQuestionAnswer.data.question_id;

        if (customQuestionAnswer?.data.free_text_answer) {
          answersByQuestionId[questionId] =
            customQuestionAnswer?.data.free_text_answer;
        } else if (customQuestionAnswer?.data.number_answer) {
          answersByQuestionId[questionId] =
            customQuestionAnswer?.data.number_answer;
        } else if (customQuestionAnswer?.data.date_answer) {
          answersByQuestionId[questionId] =
            customQuestionAnswer?.data.date_answer;
        } else if (
          customQuestionAnswer?.data.form_answer_options?.data.length
        ) {
          answersByQuestionId[questionId] =
            customQuestionAnswer?.data.form_answer_options?.data
              .map((opt) => opt.form_question_option_id)
              .filter((id): id is string => id != null);
        }

        if (restPayload.body.data[answer.question_id]) {
          const existingData = restPayload.body.data[answer.question_id] as {
            answersByQuestionId: Record<string, any>;
            answerBankId?: string;
          };
          if (existingData.answersByQuestionId) {
            existingData.answersByQuestionId = {
              ...existingData.answersByQuestionId,
              ...answersByQuestionId,
            };
          } else {
            existingData.answersByQuestionId = answersByQuestionId;
          }
        } else {
          restPayload.body.data[answer.question_id] = {
            answersByQuestionId,
            answerBankId,
            referenceId,
          };
        }
      }
    });

    // handle clearing answers of CQTs
    if (variables.deleted_answers_custom_question_ids?.length) {
      for (const questionId of variables.deleted_answers_custom_question_ids) {
        restPayload.body.data[questionId] = null;
      }
    }
    if (Object.keys(restPayload.body.data).length === 0) {
      return {
        data: {} as UpsertFormAnswer,
      };
    }

    await upsertFormAnswersREST(restPayload);
    await queryClient.invalidateQueries({
      queryKey: [useGetAnswersKey],
    });

    // Return a mock FetchResult to maintain interface compatibility
    return {
      data: {} as UpsertFormAnswer,
    };
  };
  const upsertFormAnswersStatus = React.useMemo(() => {
    if (isFormServiceEnabled) {
      return {
        remoteData: isUpsertFormAnswersRESTPending
          ? RD.loading<ApolloError, UpsertFormAnswer>(undefined)
          : RD.notAsked<ApolloError, UpsertFormAnswer>(),
      };
    }
    return upsertFormAnswersGQLStatus;
  }, [
    isFormServiceEnabled,
    isUpsertFormAnswersRESTPending,
    upsertFormAnswersGQLStatus,
  ]);

  return {
    upsertFormAnswers,
    upsertFormAnswersStatus,
  };
}
