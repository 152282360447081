import { HStack, Input, Text, VStack } from "@chakra-ui/react";
import { HTMLInputTypeAttribute } from "react";

type Props = Readonly<{
  name: string;
  label: string;
  onChange: (val: string) => void;
  placeholder?: string;
  value: string;
  type?: HTMLInputTypeAttribute;
  size?: "sm" | "md" | "lg";
  isRequired?: boolean;
  isInvalid?: boolean;
}>;

export function TextInput({
  name,
  label,
  value,
  onChange,
  placeholder = "",
  size = "md",
  type = "text",
  isRequired = false,
  isInvalid = false,
}: Props): JSX.Element {
  return (
    <VStack gap={2} width="100%" alignItems="flex-start">
      <HStack>
        <Text fontSize={size} whiteSpace="nowrap">
          {label}
        </Text>
        {isRequired && (
          <Text fontSize={size} whiteSpace="nowrap" color="red">
            *
          </Text>
        )}
      </HStack>
      <Input
        name={name}
        isInvalid={isInvalid}
        type={type}
        size={size}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </VStack>
  );
}
