import { Flex, Text } from "@chakra-ui/react";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import { formatIsoDateToOrgDateTime } from "src/services/format";
import { FeedEvent, FeedEventType } from "../helpers";

interface FeedItemProps {
  event: FeedEvent<string>;
}
export const FeedItem = ({ event }: FeedItemProps) => {
  const organization = useOrganization();

  let message = undefined;

  switch (event.type) {
    case FeedEventType.AddedTo:
    case FeedEventType.Updated:
    case FeedEventType.Submitted:
      message = (
        <>
          {event.type}{" "}
          <Text as="span" fontWeight="semibold">
            {event.value}
          </Text>{" "}
          {"to "}
          <Glossary>{event.field}</Glossary>
        </>
      );
      break;

    case FeedEventType.RemovedFrom:
      message = (
        <>
          {event.type}{" "}
          <Text as="span" fontWeight="semibold">
            {event.value}
          </Text>{" "}
          {"from "}
          <Glossary>{event.field}</Glossary>
        </>
      );
      break;
    default:
      message = (
        <>
          {event.type}{" "}
          <Text as="span" fontWeight="semibold">
            {event.value}
          </Text>{" "}
          <Glossary>
            {event.field === "Form" ? "Form response" : event.field}
          </Glossary>
        </>
      );
      break;
  }
  return (
    <Flex>
      <Text whiteSpace="normal">
        <Text as="span" fontWeight="semibold">
          {event.author
            ? event.author.full_name
            : // Org admins can't see user info of Avela admins, so we assume the author is Avela admin.
              "Avela admin"}
        </Text>{" "}
        {message}
        {". "}
        <Text as="span" color="blackAlpha.700" fontSize="xs">
          {event.isoTimestamp &&
            formatIsoDateToOrgDateTime(event.isoTimestamp, organization)}
        </Text>
      </Text>
    </Flex>
  );
};
