import { gql } from "@apollo/client";

export const APPLICANT_ATTENDING_SCHOOLS_FRAGMENT = gql`
  fragment ApplicantAttendingSchoolsFragment on person {
    applicant_attending_schools {
      person_id
      enrollment_period_id
      attendance_status
      school_id
      description
    }
  }
`;

export const APPLICANT_FORM_SCHOOL_RANKS_FRAGMENT = gql`
  fragment ApplicantFormSchoolRanksFragment on person {
    forms {
      form_school_ranks {
        school_id
      }
    }
  }
`;

export const PERSON_FRAGMENT = gql`
  fragment PersonFragment on person {
    id
    first_name
    middle_name
    last_name
    birth_date
    street_address
    street_address_line_2
    city
    state
    zip_code
    active
    reference_id
    phone_number
    sms_okay
    email_address
    email_okay
    avatar
    updated_at
    preferred_language
    user {
      id
      name
    }
  }

  fragment PersonTotals on person_aggregate {
    totals: aggregate {
      total: count
    }
  }
`;

export const PERSON_BASIC_FRAGMENT = gql`
  fragment PersonBasicFragment on person {
    id
    first_name
    middle_name
    last_name
    phone_number
    reference_id
    email_address
    sms_okay
    email_okay
  }
`;

export const RELATIONSHIP_PERSON_FRAGMENT = gql`
  fragment RelationshipPersonFragment on person {
    id
    reference_id
    first_name
    middle_name
    last_name
    person_type
    latest_forms: forms(
      where: { deleted_at: { _is_null: true } }
      order_by: { status_updated_at: desc }
    ) {
      id
      status
      status_updated_at
      form_template {
        id
        name
        enrollment_period {
          id
          name
        }
      }
      form_school_ranks {
        school_id
      }
    }
  }
`;

export const SIBLING_FRAGMENT = gql`
  ${PERSON_FRAGMENT}
  ${RELATIONSHIP_PERSON_FRAGMENT}
  ${APPLICANT_ATTENDING_SCHOOLS_FRAGMENT}
  ${APPLICANT_FORM_SCHOOL_RANKS_FRAGMENT}
  fragment SiblingFragment on person {
    ...PersonFragment
    ...RelationshipPersonFragment
    ...ApplicantAttendingSchoolsFragment
    ...ApplicantFormSchoolRanksFragment
  }
`;
