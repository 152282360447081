import { ReactNode } from "react";
import { Button } from "@chakra-ui/react";

export function TbButton({
  children,
  onClick,
  isDisabled,
  title,
  "aria-label": ariaLabel,
  size = "sm",
}: {
  children: ReactNode;
  isDisabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  "aria-label"?: string;
  size?: "sm" | "md" | "lg";
}): JSX.Element {
  return (
    <Button
      isDisabled={isDisabled}
      onClick={onClick}
      title={title}
      aria-label={ariaLabel || title}
      variant="ghost"
      size={size}
      height="8"
      padding="0"
    >
      {children}
    </Button>
  );
}

export function TbSmallButton({
  children,
  onClick,
  isDisabled,
  title,
  "aria-label": ariaLabel,
}: {
  children: ReactNode;
  isDisabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  "aria-label"?: string;
}): JSX.Element {
  return (
    <Button
      isDisabled={isDisabled}
      onClick={onClick}
      title={title}
      aria-label={ariaLabel || title}
      variant="ghost"
      width="auto"
      height="auto"
      minWidth="0"
      minHeight="0"
      padding="0"
    >
      {children}
    </Button>
  );
}
