import { Flex, HStack, Skeleton, Text } from "@chakra-ui/react";
import { RiArrowRightLine } from "react-icons/ri";
import { useEnrollmentPeriod } from "src/components/Providers/EnrollmentPeriodProvider";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GET_PARENT_PROCESSED_FORM_STAT } from "./graphql/queries";
import { useMemo } from "react";

type ParentProcessedFormProps = {};

export function ParentProcessedForm(props: ParentProcessedFormProps) {
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();

  const current = new Date();
  const yesterday = new Date(current.getTime() - 24 * 60 * 60 * 1000);
  const twoDaysAgo = new Date(current.getTime() - 2 * 24 * 60 * 60 * 1000);

  const { remoteData: currentPeriodData } = useRemoteDataQuery<
    GQL.GetParentProcessedFormStat,
    GQL.GetParentProcessedFormStatVariables
  >(GET_PARENT_PROCESSED_FORM_STAT, {
    variables: {
      enrollment_period_id: selectedEnrollmentPeriod?.id ?? "",
      begin_at: yesterday.toISOString(),
      end_at: current.toISOString(),
    },
    skip: !selectedEnrollmentPeriod?.id,
  });

  const { remoteData: previousPeriodData } = useRemoteDataQuery<
    GQL.GetParentProcessedFormStat,
    GQL.GetParentProcessedFormStatVariables
  >(GET_PARENT_PROCESSED_FORM_STAT, {
    variables: {
      enrollment_period_id: selectedEnrollmentPeriod?.id ?? "",
      begin_at: twoDaysAgo.toISOString(),
      end_at: yesterday.toISOString(),
    },
    skip: !selectedEnrollmentPeriod?.id,
  });

  const currentPeriodCount = useMemo(
    () =>
      currentPeriodData.hasData()
        ? currentPeriodData.data.form_tracking_dates_aggregate.aggregate?.count
        : undefined,
    [currentPeriodData]
  );

  const previousPeriodCount = useMemo(
    () =>
      previousPeriodData.hasData()
        ? previousPeriodData.data.form_tracking_dates_aggregate.aggregate?.count
        : undefined,
    [previousPeriodData]
  );

  const changePercentage = useMemo(() => {
    if (currentPeriodCount === undefined || previousPeriodCount === undefined) {
      return undefined;
    }
    if (previousPeriodCount === 0) {
      return undefined;
    }
    return (
      ((currentPeriodCount - previousPeriodCount) / previousPeriodCount) * 100
    );
  }, [currentPeriodCount, previousPeriodCount]);

  const isPositiveChange = useMemo(() => {
    return changePercentage && changePercentage > 0;
  }, [changePercentage]);

  return (
    <Flex
      direction="column"
      gap={4}
      border="solid 1px"
      bgColor="gray.50"
      borderColor="gray.200"
      borderRadius="md"
      p={4}
      w="full"
      minW="17rem"
    >
      <Text fontSize="md" color="gray.800" fontWeight="500">
        New responses
      </Text>
      <Flex direction="row" gap={4} justify="space-between" align="center">
        <HStack gap={2}>
          {currentPeriodCount !== undefined ? (
            <Text fontSize="2xl" color="gray.800" fontWeight="600">
              {currentPeriodCount}
            </Text>
          ) : (
            <Skeleton height="45px" minW="100px" />
          )}

          {changePercentage && !isNaN(changePercentage) && (
            <Text
              fontSize="sm"
              bgColor={isPositiveChange ? "green.200" : "orange.100"}
              borderRadius="md"
              pl={2}
              pr={2}
            >
              {isPositiveChange ? "+ " : ""}
              {changePercentage}%
            </Text>
          )}
        </HStack>
        <RiArrowRightLine style={{ width: 14, height: 14 }} />
      </Flex>
    </Flex>
  );
}
