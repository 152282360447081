import { ApolloError, useReactiveVar } from "@apollo/client";
import { Flex } from "@chakra-ui/react";
import { Form, Formik, FormikProps } from "formik";
import React, { useMemo, useEffect } from "react";
import { InfoAlert } from "src/components/Feedback/InfoAlert";
import {
  FormStepLayout,
  StepProps,
} from "src/components/Layout/FormStepLayout";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { useFormSteps } from "src/hooks/useFormSteps";
import { Answer, Question } from "src/services/formTemplate";
import { Question as QuestionType } from "src/types/formTemplate";
import { calculateNewQuestionsCount } from "src/services/formTemplate/generalSection";
import * as AFF from "src/services/formTemplateFilters";
import { validateFormTemplate } from "src/services/formTemplateValidations";
import { GeneralSection, WithId } from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";
import { QuestionList } from "./QuestionList";
import { FormButtons } from "./components/Layout/FormButtons";
import { useAutosaveReducer } from "./hooks/useAutosaveReducer";
import { rankedSchoolsEditVar } from "./store";

type Props = {
  answers: GQL.GetFormAnswersById;
  formId: uuid;
  applicant: AFF.Types.Applicant;
  schoolRanksRemoteData: RemoteData<ApolloError, GQL.GetSchoolsRank>;
  previousFormSchoolIds: uuid[];
  section: GeneralSection<WithId>;
  verificationResults: GQL.FormFragment_form_verification_results[];
  hasBeenSubmittedBefore: boolean | null;
  onChangeFormQuestion?: (questionId: string) => void;
} & StepProps;

type FormContentProps = {
  formId: uuid;
  applicant: AFF.Types.Applicant;
  formikProps: FormikProps<any>;
  rankedSchoolIds: uuid[];
  previousFormSchoolIds: uuid[];
  questions: QuestionType<WithId>[];
  verificationResults: GQL.FormFragment_form_verification_results[];
  showNewQuestionsAlert: boolean;
  newQuestionsCount: number;
  clearNewQuestionsAlert: () => void;
  onAutosave: (questionId: uuid, saving: boolean) => void;
  onChangeFormQuestion?: (questionId: string) => void;
  hasBeenSubmittedBefore: boolean | null;
};

/**
 * This in-between container component helps to trigger validation on mount
 * and display error messages when applicable.
 */
const StepGeneralSectionContainer: React.FC<FormContentProps> = ({
  formId,
  applicant,
  formikProps,
  rankedSchoolIds,
  previousFormSchoolIds,
  questions,
  verificationResults,
  showNewQuestionsAlert,
  newQuestionsCount,
  clearNewQuestionsAlert,
  onAutosave,
  onChangeFormQuestion,
  hasBeenSubmittedBefore,
}) => {
  useEffect(() => {
    if (!hasBeenSubmittedBefore) {
      return;
    }
    formikProps.validateForm().then((errors) => {
      Object.keys(errors).forEach((key) => {
        formikProps.setFieldTouched(key);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  const completeQuestions = Question.getCompleteQuestions(questions);
  const applicableQuestions = Question.groupByVerifications(
    Question.getCompleteApplicableQuestions(questions, formikProps.values, {
      rankedSchoolIds,
      previousFormSchoolIds,
    })
  );

  return (
    <Flex direction="column" gap={5}>
      {showNewQuestionsAlert && (
        <InfoAlert closeAlert={clearNewQuestionsAlert}>
          {newQuestionsCount > 1
            ? `${newQuestionsCount} new questions added`
            : `${newQuestionsCount} new question added`}
        </InfoAlert>
      )}
      <QuestionList
        formId={formId}
        applicant={applicant}
        completeQuestions={completeQuestions}
        applicableQuestions={applicableQuestions}
        formikProps={formikProps}
        verificationResults={verificationResults}
        onAutosave={onAutosave}
        onChangeFormQuestion={onChangeFormQuestion}
      />
    </Flex>
  );
};

export const StepGeneralSection: React.FC<Props> = ({
  formId,
  answers,
  applicant,
  schoolRanksRemoteData,
  previousFormSchoolIds,
  section,
  verificationResults,
  hasBeenSubmittedBefore,
  onChangeFormQuestion,
  ...stepProps
}) => {
  const { onNext, onPrevious } = useFormSteps(stepProps);
  const rankedSchoolEdit = useReactiveVar(rankedSchoolsEditVar);

  const newQuestionsCount = React.useMemo(() => {
    if (rankedSchoolEdit === null) {
      return 0;
    }

    const newQuestionsCount = calculateNewQuestionsCount(
      section.questions,
      rankedSchoolEdit
    );

    return newQuestionsCount;
  }, [rankedSchoolEdit, section.questions]);

  const clearNewQuestionsAlert = () => {
    rankedSchoolsEditVar(null);
  };

  const handleSubmit = async () => {
    if (autosaveStatus === "Saving") {
      return;
    }
    clearNewQuestionsAlert();
    onNext();
  };

  const remoteData = schoolRanksRemoteData.map((data) => {
    return data.form_school_rank.map((rankedSchool) => rankedSchool.school.id);
  });

  const { autosaveStatus, onAutosave } = useAutosaveReducer();

  const initialValues = useMemo(
    () =>
      Answer.getFormikInitialValues(
        section.questions,
        answers.form_answer,
        answers.grades_answer,
        answers.form_address,
        answers.custom_question_answer
      ),
    [section.questions, answers]
  );

  const showNewQuestionsAlert = newQuestionsCount > 0 && hasBeenSubmittedBefore;

  return (
    <ParentRemoteDataLayout remoteData={remoteData}>
      {(rankedSchoolIds) => (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validate={validateFormTemplate(section.questions, {
            rankedSchoolIds,
            previousFormSchoolIds,
          })}
          validateOnMount={false} // need to disable this since it's causing weird validation behavior since we introduce refetchAnswer. See: https://app.asana.com/0/0/1203247877978830/1203832069318649/f
        >
          {(formikProps) => (
            <FormStepLayout
              noValidate
              as={Form}
              title={section.title}
              description={section.description}
              buttons={(buttonProps) => (
                <FormButtons
                  {...buttonProps}
                  saveStatus={autosaveStatus}
                  overridePreviousButton={{
                    action: () => {
                      clearNewQuestionsAlert();
                      onPrevious();
                    },
                  }}
                  overrideNextButton={{
                    disabled: !formikProps.isValid,
                    action: () => {
                      // Do nothing here, and instead, next button will trigger form submission
                    },
                  }}
                  hasBeenSubmittedBefore={hasBeenSubmittedBefore}
                />
              )}
              {...stepProps}
              content={
                <StepGeneralSectionContainer
                  formId={formId}
                  applicant={applicant}
                  formikProps={formikProps}
                  rankedSchoolIds={rankedSchoolIds}
                  previousFormSchoolIds={previousFormSchoolIds}
                  questions={section.questions}
                  verificationResults={verificationResults}
                  showNewQuestionsAlert={showNewQuestionsAlert ?? false}
                  newQuestionsCount={newQuestionsCount}
                  clearNewQuestionsAlert={clearNewQuestionsAlert}
                  onAutosave={onAutosave}
                  onChangeFormQuestion={onChangeFormQuestion}
                  hasBeenSubmittedBefore={hasBeenSubmittedBefore}
                />
              }
            />
          )}
        </Formik>
      )}
    </ParentRemoteDataLayout>
  );
};
