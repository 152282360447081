import { ChevronDownIcon, ChevronUpIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import * as DateFns from "date-fns";
import { useFlags } from "flagsmith/react";
import React, { useMemo } from "react";
import { RiChat2Line } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import { DataLabel } from "src/components/Inputs/DataLabel";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useOrganization } from "src/hooks/useOrganization";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { useSendMessage } from "src/hooks/useSendMessage";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { address, fullName } from "src/services/format";
import { getReferenceId } from "src/services/id";
import { getGuardians } from "src/services/person";
import { checkUnverifiedCommsPerOrg } from "src/services/unverifiedCommsHelpers";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { getSiblings } from "../../students/tables/helpers";

type Props = {
  form: GQL.GetFormViewById_form_by_pk;
};

export const StudentProfile: React.FC<Props> = ({ form }) => {
  const organization = useOrganization();
  const flags = useFlags([
    "unverified-communications",
    "form-detail-family-quick-links",
    "view-student-and-parent-profiles",
  ]);
  const { handleSendMessage } = useSendMessage(form.form_template.id);
  const formParents = getGuardians(form.person);
  const { isElevatedSchoolAdmin, applyingSchoolIds, attendingSchoolIds } =
    useSchoolAdmin();

  const onSendMessageClick = () => {
    const parentIds = formParents.map((parent) => parent.id);
    handleSendMessage(parentIds, [form.person.id], [form.id]);
  };

  const userPermissions = useUserPermissions();

  const isUnverifiedCommsEnabledForOrg = useMemo(
    () =>
      checkUnverifiedCommsPerOrg(
        organization,
        flags["unverified-communications"].enabled
      ),
    [flags, organization]
  );

  const [isExpanded, setIsExpanded] = React.useState(false);
  const siblings = useMemo(
    () =>
      getSiblings(
        form.person,
        isElevatedSchoolAdmin,
        applyingSchoolIds,
        attendingSchoolIds
      ),
    [form.person, isElevatedSchoolAdmin, applyingSchoolIds, attendingSchoolIds]
  );
  const showSiblings = flags["form-detail-family-quick-links"]?.enabled;

  const visibleSiblings = useMemo(() => {
    if (isExpanded) return siblings;
    return siblings.slice(0, 3);
  }, [siblings, isExpanded]);

  return (
    <Flex
      borderRadius="md"
      border="1px solid"
      borderColor="gray.300"
      direction="column"
      padding={4}
      minWidth="15rem"
    >
      <Flex direction="column" gap={3}>
        {/* LOI: needs better way handle multiple role level permissions vs user level permissions */}
        <Flex direction="column">
          {userPermissions.hasOne("user:update") ? (
            <Link
              alignSelf="center"
              fontSize="lg"
              color="primary.500"
              fontWeight="600"
              as={RouterLink}
              to={Url.OrgAdmin.Students.edit(organization, form.person.id)}
            >
              {fullName(form.person)}
            </Link>
          ) : flags["view-student-and-parent-profiles"].enabled &&
            userPermissions.hasOne("user:read") ? (
            <Link
              alignSelf="center"
              fontSize="lg"
              color="primary.500"
              fontWeight="600"
              as={RouterLink}
              to={Url.OrgAdmin.Students.view(organization, form.person.id)}
            >
              {fullName(form.person)}
            </Link>
          ) : (
            <Text alignSelf="center" fontSize="lg" fontWeight="600">
              {fullName(form.person)}
            </Text>
          )}
          <Text alignSelf="center" color="gray.500" fontSize="sm">
            ID: {getReferenceId(form.person)}
          </Text>
        </Flex>
        {form.person.birth_date && (
          <Box>
            <DataLabel>Date of birth</DataLabel>
            <Text>
              {DateFns.format(
                DateFns.parse(form.person.birth_date, "yyyy-MM-dd", new Date()),
                "MM/dd/yyyy"
              )}
            </Text>
          </Box>
        )}
        <Box>
          <DataLabel>Address</DataLabel>
          <Text>{address(form.person)}</Text>
        </Box>
        {form.form_attending_school?.school?.name && (
          <Box>
            <DataLabel>Current School</DataLabel>
            <Text>{form.form_attending_school.school.name}</Text>
          </Box>
        )}
        <Divider />
        {formParents.map((parent) => (
          <React.Fragment key={parent.id}>
            <Box>
              <DataLabel>Parent or Guardian</DataLabel>
              {userPermissions.hasOne("user:update") ? (
                <Link
                  color="primary.500"
                  as={RouterLink}
                  to={Url.OrgAdmin.Parents.edit(organization, parent.id)}
                >
                  {fullName(parent)}
                </Link>
              ) : flags["view-student-and-parent-profiles"].enabled &&
                userPermissions.hasOne("user:read") ? (
                <Link
                  color="primary.500"
                  as={RouterLink}
                  to={Url.OrgAdmin.Parents.view(organization, parent.id)}
                >
                  {fullName(parent)}
                </Link>
              ) : (
                fullName(parent)
              )}
            </Box>
            {parent.email_address && (
              <Box>
                <HStack>
                  <DataLabel>Email</DataLabel>
                  {parent.email_address === parent?.user?.name && (
                    <Badge colorScheme="gray" variant="solid" marginLeft="1rem">
                      {"Linked"}
                    </Badge>
                  )}
                </HStack>
                <HStack justifyContent="space-between">
                  <Text
                    max-width="calc(100% - 40px)"
                    overflow="hidden"
                    white-space="nowrap"
                    text-overflow="ellipsis"
                  >
                    {parent.email_address}
                  </Text>
                  <WithUserPermissions permissions={["message:create"]}>
                    {(isUnverifiedCommsEnabledForOrg || parent.email_okay) && (
                      <Tooltip label="Send Message">
                        <IconButton
                          aria-label="send-message"
                          icon={<RiChat2Line />}
                          variant="ghost"
                          onClick={onSendMessageClick}
                        />
                      </Tooltip>
                    )}
                  </WithUserPermissions>
                </HStack>
              </Box>
            )}
            {parent.phone_number && (
              <Box>
                <HStack>
                  <DataLabel>Phone number</DataLabel>
                  {parent.phone_number === parent?.user?.name && (
                    <Badge colorScheme="gray" variant="solid" marginLeft="1rem">
                      {"Linked"}
                    </Badge>
                  )}
                </HStack>
                <HStack justifyContent="space-between">
                  <Text>{parent.phone_number}</Text>
                  <WithUserPermissions permissions={["message:create"]}>
                    {parent.sms_okay && (
                      <Tooltip label="Send Message">
                        <IconButton
                          aria-label="send-message"
                          icon={<RiChat2Line />}
                          variant="ghost"
                          onClick={onSendMessageClick}
                        />
                      </Tooltip>
                    )}
                  </WithUserPermissions>
                </HStack>
              </Box>
            )}
          </React.Fragment>
        ))}

        {showSiblings && siblings.length > 0 && (
          <>
            <Divider />
            <Box>
              <HStack marginBottom={2}>
                <DataLabel>Siblings</DataLabel>
                <Tooltip label="Other applicants that share at least one of the same parent/guardian">
                  <Icon
                    as={InfoIcon}
                    color="gray.500"
                    boxSize={3}
                    cursor="help"
                  />
                </Tooltip>
              </HStack>
              <Flex direction="column" gap={2}>
                {visibleSiblings.map((sibling) => (
                  <Box key={sibling.id}>
                    <HStack>
                      {userPermissions.hasOne("user:update") ? (
                        <Link
                          color="primary.500"
                          as={RouterLink}
                          to={Url.OrgAdmin.Students.edit(
                            organization,
                            sibling.id
                          )}
                        >
                          {fullName(sibling)}
                        </Link>
                      ) : flags["view-student-and-parent-profiles"].enabled &&
                        userPermissions.hasOne("user:read") ? (
                        <Link
                          color="primary.500"
                          as={RouterLink}
                          to={Url.OrgAdmin.Students.view(
                            organization,
                            sibling.id
                          )}
                        >
                          {fullName(sibling)}
                        </Link>
                      ) : (
                        <Text>{fullName(sibling)}</Text>
                      )}
                      {sibling.birth_date && (
                        <Text fontSize="sm" color="gray.500">
                          {" "}
                          {DateFns.format(
                            DateFns.parse(
                              sibling.birth_date,
                              "yyyy-MM-dd",
                              new Date()
                            ),
                            "MM/dd/yyyy"
                          )}
                        </Text>
                      )}
                    </HStack>
                  </Box>
                ))}
                {siblings.length > 3 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setIsExpanded(!isExpanded)}
                    rightIcon={
                      isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />
                    }
                  >
                    {isExpanded
                      ? "Show less"
                      : `Show ${siblings.length - 3} more`}
                  </Button>
                )}
              </Flex>
            </Box>
          </>
        )}
      </Flex>
    </Flex>
  );
};
