import { Flex } from "@chakra-ui/react";
import React from "react";
import { RiAddFill } from "react-icons/ri";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { AddSectionButton } from "../../components/AddSectionButton";
import { useEditFormTemplateContext } from "./context";
import { FormTemplateSection } from "./FormTemplateSection";
import { ALL_SECTION_TYPES } from "./services";
import { HasuraRole } from "src/types/hasuraRole";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { useFlags } from "flagsmith/react";

type Props = {
  organizationId: uuid;
  verificationOptions: AF.FormVerification<AF.WithId>[];
  gradesConfig: GQL.GetGradesConfigByOrganization_grade_config[];
};
export const Tab: React.FC<Props> = ({
  organizationId,
  verificationOptions,
  gradesConfig
}) => {
  const { state } = useEditFormTemplateContext();
  const sortedSections = state?.get("sortedSections");

  const flags = useFlags(["form-builder-orgadmin-edit"]);
  const isFormBuilderFullEditEnabled =
    flags["form-builder-orgadmin-edit"].enabled;
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);

  return (
    <Flex direction="column" gap={4}>
      <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}>
        {(!sortedSections || sortedSections?.size === 0) &&
          (isAvelaAdmin || isFormBuilderFullEditEnabled) && (
            <AddSectionButton
              applicableSectionTypes={ALL_SECTION_TYPES}
              newOrder={0}
              leftIcon={<RiAddFill />}
              width="100%"
              height="5rem"
            />
          )}
      </WithRequiredHasuraRoles>

      {sortedSections?.map((section, index) => {
        return (
          <FormTemplateSection
            key={index}
            sectionId={section}
            verificationOptions={verificationOptions}
            organizationId={organizationId}
            gradesConfig={gradesConfig}
            order={index}
          />
        );
      })}
    </Flex>
  );
};
