import { Box, Card, Flex, Text } from "@chakra-ui/react";
import Immutable from "immutable";
import React from "react";
import { RiAddFill } from "react-icons/ri";
import { QuestionForm } from "src/components/Form/QuestionForm";
import { QuestionFormNew } from "src/components/Form/QuestionForm/New";
import { DisclaimerForm } from "src/components/Form/SectionForm/DisclaimerForm";
import { SchoolRankingForm } from "src/components/Form/SectionForm/SchoolRankingForm";
import { SectionForm } from "src/components/Form/SectionForm/SectionForm";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { AddQuestionButton } from "../../components/AddQuestionButton";
import { AddSectionButton } from "../../components/AddSectionButton";
import { INSERT_FORM_VERIFICATIONS } from "../../graphql/mutations";
import {
  GET_FORM_VERIFICATIONS,
  GET_SCHOOLS_FOR_FORM_TEMPLATE_EDIT
} from "../../graphql/queries";
import { QuestionId, SectionId } from "../../types/state";
import { useEditFormTemplateContext } from "./context";
import {
  getApplicableSectionTypes,
  toInsertFormVerificationVariables
} from "./services";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { useFlags } from "flagsmith/react";

export const EDITABLE_SECTION_TYPES = [
  AF.PreRankingSectionType,
  AF.GeneralSectionType,
  AF.DisclaimerSectionType,
  AF.SchoolRankingSectionType
];

export type EditableSection =
  | AF.PreRankingSection<AF.WithId>
  | AF.GeneralSection<AF.WithId>
  | AF.DisclaimerSection<AF.WithId>
  | AF.SchoolRankingSection<AF.WithId>;

interface Props {
  sectionId: SectionId;
  verificationOptions: AF.FormVerification<AF.WithId>[];
  organizationId: uuid;
  gradesConfig: GQL.GetGradesConfigByOrganization_grade_config[];
  order: number;
}

export const FormTemplateSection = ({
  sectionId,
  verificationOptions,
  gradesConfig,
  order
}: Props): React.ReactElement => {
  const { dispatch, state, formTemplateId } = useEditFormTemplateContext();

  const [insertFormVerification] = useRemoteDataMutation<
    GQL.InsertFormVerifications,
    GQL.InsertFormVerificationsVariables
  >(INSERT_FORM_VERIFICATIONS);

  const { remoteData: schoolsResult } = useRemoteDataQuery<
    GQL.GetSchoolsForFormTemplateEdit,
    GQL.GetSchoolsForFormTemplateEditVariables
  >(GET_SCHOOLS_FOR_FORM_TEMPLATE_EDIT, {
    variables: {
      formTemplateId: formTemplateId ?? ""
    },
    skip: formTemplateId === undefined
  });

  const flags = useFlags(["form-builder-orgadmin-edit"]);
  const isFormBuilderFullEditEnabled =
    flags["form-builder-orgadmin-edit"].enabled;
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);

  const schools = React.useMemo(() => {
    return schoolsResult.map((result) => {
      return result.school.map((school) => ({
        id: school.id,
        label: school.name
      }));
    });
  }, [schoolsResult]);

  const addNewQuestion = React.useCallback(
    (sectionId: uuid, atIndex: number) => () => {
      if (!dispatch) {
        return;
      }

      dispatch({
        type: "AddNewQuestion",
        sectionId,
        newQuestionId: window.crypto.randomUUID(),
        atIndex
      });
    },
    [dispatch]
  );

  const onCancelButtonClickHandler = React.useCallback(
    (questionId: uuid) => {
      if (!dispatch) {
        return;
      }

      dispatch({
        type: "RemoveNewQuestion",
        questionId: questionId
      });
    },
    [dispatch]
  );

  const onNewQuestionUpdateHandler = React.useCallback(
    async (newQuestion: Draft.NewQuestion) => {
      if (!dispatch || !formTemplateId) {
        return;
      }

      dispatch({
        type: "UpdateNewQuestion",
        newQuestion
      });

      const verificationInsertsVariable = toInsertFormVerificationVariables(
        formTemplateId,
        newQuestion
      );
      if (verificationInsertsVariable.inserts.length > 0) {
        const result = await insertFormVerification({
          variables: verificationInsertsVariable,
          refetchQueries: [GET_FORM_VERIFICATIONS]
        });
        if (!result.data?.insert_form_verification) {
          throw new Error("Unable to create new verification");
        }

        dispatch({
          type: "SwitchToExistingVerification",
          question: newQuestion,
          formVerifications: result.data.insert_form_verification.returning.map(
            (v) => ({
              id: v.id,
              label: v.label
            })
          )
        });
      }

      dispatch({ type: "Save", formTemplateId });
    },
    [formTemplateId, dispatch, insertFormVerification]
  );

  const onUpdateHandler = React.useCallback(
    async (updatedQuestion: Draft.Question): Promise<void> => {
      if (!dispatch || !formTemplateId) {
        return;
      }
      const verificationInsertsVariable = toInsertFormVerificationVariables(
        formTemplateId,
        updatedQuestion
      );
      if (verificationInsertsVariable.inserts.length > 0) {
        const result = await insertFormVerification({
          variables: verificationInsertsVariable,
          refetchQueries: [GET_FORM_VERIFICATIONS]
        });
        if (!result.data?.insert_form_verification) {
          throw new Error("Unable to create new verification");
        }

        dispatch({
          type: "UpdateQuestion",
          question: updatedQuestion
        });
        dispatch({
          type: "SwitchToExistingVerification",
          question: updatedQuestion,
          formVerifications: result.data.insert_form_verification.returning.map(
            (v) => ({
              id: v.id,
              label: v.label
            })
          )
        });
      } else {
        dispatch({
          type: "UpdateQuestion",
          question: updatedQuestion
        });
      }

      if (formTemplateId) {
        dispatch({ type: "Save", formTemplateId });
      }
    },
    [formTemplateId, dispatch, insertFormVerification]
  );

  const { confirm, confirmationDialog } = useConfirmationDialog({
    body: "This will remove the question and any changes that have been made to it.",
    header: "Remove unpublished question?",
    confirmButton: {
      label: "Remove",
      colorScheme: "red"
    }
  });
  const onRemoveHandler = React.useCallback(
    async (question: Draft.Question) => {
      if (await confirm()) {
        if (!dispatch || !formTemplateId) {
          return;
        }

        dispatch({ type: "RemoveNewQuestion", questionId: question.id });
        dispatch({ type: "Save", formTemplateId });
      }
    },
    [formTemplateId, confirm, dispatch]
  );

  const newSections = state?.get("newSections") ?? Immutable.Map();
  const sections = state?.get("sections") ?? Immutable.Map();
  const newSectionObject = newSections.get(sectionId);
  const existingSectionObject = sections.get(sectionId);
  const section = newSectionObject ?? existingSectionObject?.original;
  const isNewSection = newSectionObject !== undefined;

  const allSections = [
    ...newSections.valueSeq().toArray(),
    ...sections
      .valueSeq()
      .toArray()
      .map((s) => s.original)
  ];

  const sectionElement = React.useMemo(() => {
    if (!section) return <></>;

    switch (section.type) {
      case AF.PreRankingSectionType:
      case AF.GeneralSectionType:
        const existingQuestionsMap = Immutable.Map<
          QuestionId,
          AF.Question<AF.WithId>
        >(section.questions.map((q) => [QuestionId(q.id), q]));

        const newQuestions = state?.get("newQuestions") ?? Immutable.Map();
        const sortedQuestions = state
          ?.get("sortedQuestions")
          .get(SectionId(section.id));

        return (
          <>
            {sortedQuestions && sortedQuestions.size > 0 ? (
              <>
                <WithRequiredHasuraRoles
                  roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
                >
                  {(isAvelaAdmin || isFormBuilderFullEditEnabled) && (
                    <AddQuestionButton onClick={addNewQuestion(section.id, 0)}>
                      Add question
                    </AddQuestionButton>
                  )}
                </WithRequiredHasuraRoles>
                {sortedQuestions?.map((questionKey, index) => {
                  const existingQuestion =
                    existingQuestionsMap.get(questionKey);
                  const newQuestion = newQuestions.get(questionKey);

                  const draft = state?.get("questions").get(questionKey)?.draft;
                  const hasChanges = Draft.hasChanges(existingQuestion, draft);
                  const question = draft ?? existingQuestion ?? newQuestion;

                  const questionId =
                    question === undefined
                      ? questionKey.get("questionId")
                      : question.id;

                  const otherNewQuestions: readonly Draft.Question[] =
                    state
                      ?.get("newQuestions")
                      .filterNot((_, key) => key.equals(QuestionId(questionId)))
                      .valueSeq()
                      .toArray() ?? [];

                  const otherExistingQuestions: readonly Draft.Question[] =
                    state
                      ?.get("questions")
                      .filterNot((_, key) => key.equals(QuestionId(questionId)))
                      .map(
                        (q) => q.draft ?? Draft.fromOriginalQuestion(q.original)
                      )
                      .valueSeq()
                      .toArray() ?? [];

                  const otherQuestions = otherNewQuestions.concat(
                    otherExistingQuestions
                  );

                  if (question === undefined) {
                    return (
                      <Flex key={questionId} direction="column" gap="2">
                        <QuestionFormNew
                          key={questionId}
                          sectionType={section.type}
                          newQuestionId={questionId}
                          schools={schools}
                          onCancelButtonClick={() =>
                            onCancelButtonClickHandler(questionId)
                          }
                          onUpdate={onNewQuestionUpdateHandler}
                          verificationOptions={verificationOptions}
                          gradesConfig={gradesConfig}
                          otherQuestions={otherQuestions}
                        />
                        <WithRequiredHasuraRoles
                          roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
                        >
                          {(isAvelaAdmin || isFormBuilderFullEditEnabled) && (
                            <AddQuestionButton
                              onClick={addNewQuestion(section.id, index + 1)}
                            >
                              Add question
                            </AddQuestionButton>
                          )}
                        </WithRequiredHasuraRoles>
                      </Flex>
                    );
                  }

                  return (
                    <Flex key={questionId} direction="column" gap="2">
                      <QuestionForm
                        sectionType={section.type}
                        question={
                          state?.get("questions").get(QuestionId(questionId))
                            ?.draft ?? Draft.fromOriginalQuestion(question)
                        }
                        hasChanges={hasChanges}
                        verificationOptions={verificationOptions}
                        schools={schools}
                        onRemove={onRemoveHandler}
                        onUpdate={onUpdateHandler}
                        gradesConfig={gradesConfig}
                        otherQuestions={otherQuestions}
                      />
                      <WithRequiredHasuraRoles
                        roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
                      >
                        {(isAvelaAdmin || isFormBuilderFullEditEnabled) && (
                          <AddQuestionButton
                            onClick={addNewQuestion(section.id, index + 1)}
                          >
                            Add question
                          </AddQuestionButton>
                        )}
                      </WithRequiredHasuraRoles>
                    </Flex>
                  );
                })}
              </>
            ) : (
              <WithRequiredHasuraRoles
                roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
              >
                {(isAvelaAdmin || isFormBuilderFullEditEnabled) && (
                  <Card
                    sx={{
                      background: "gray.50",
                      padding: 8,
                      cursor: "pointer"
                    }}
                    onClick={addNewQuestion(section.id, 0)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <RiAddFill />
                      <Text size="sm" ml="2">
                        Add first question
                      </Text>
                    </Box>
                  </Card>
                )}
              </WithRequiredHasuraRoles>
            )}
          </>
        );

      case AF.DisclaimerSectionType:
        return (
          <DisclaimerForm
            original={section}
            draft={state?.get("disclaimers").get(SectionId(section.id))?.draft}
            onUpdate={(updatedDisclaimer) => {
              if (!dispatch) {
                return;
              }

              dispatch({
                type: "UpdateDisclaimer",
                disclaimerSection: updatedDisclaimer
              });
              if (formTemplateId) {
                dispatch({ type: "Save", formTemplateId });
              }
            }}
          />
        );

      case AF.SchoolRankingSectionType:
        return (
          <SchoolRankingForm
            original={section}
            draft={
              state?.get("sections").get(SectionId(section.id))
                ?.draft as Draft.SchoolRanking
            }
            onUpdate={(updatedSchoolRanking) => {
              if (!dispatch) {
                return;
              }

              dispatch({
                type: "UpdateSchoolRanking",
                schoolRanking: updatedSchoolRanking
              });
              if (formTemplateId) {
                dispatch({ type: "Save", formTemplateId });
              }
            }}
          />
        );

      default:
        const _exhaustiveCheck: never = section;
        return _exhaustiveCheck;
    }
  }, [
    section,
    state,
    isAvelaAdmin,
    isFormBuilderFullEditEnabled,
    addNewQuestion,
    verificationOptions,
    schools,
    onRemoveHandler,
    onUpdateHandler,
    gradesConfig,
    onNewQuestionUpdateHandler,
    onCancelButtonClickHandler,
    dispatch,
    formTemplateId
  ]);

  const [applicableSectionTypesBefore, applicableSectionTypesAfter] =
    getApplicableSectionTypes(order, allSections, section);

  return (
    <>
      <Flex direction="column" gap={4}>
        {applicableSectionTypesBefore.length > 0 && (
          <WithRequiredHasuraRoles
            roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
          >
            {(isAvelaAdmin || isFormBuilderFullEditEnabled) && (
              <AddSectionButton
                applicableSectionTypes={applicableSectionTypesBefore}
                newOrder={order}
              />
            )}
          </WithRequiredHasuraRoles>
        )}
        {section && EDITABLE_SECTION_TYPES.includes(section.type) && (
          <>
            <SectionForm
              original={section}
              draft={state?.get("sections").get(SectionId(section.id))?.draft}
              isNewSection={isNewSection}
            />

            {sectionElement}

            {applicableSectionTypesAfter.length > 0 && (
              <WithRequiredHasuraRoles
                roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
              >
                {(isAvelaAdmin || isFormBuilderFullEditEnabled) && (
                  <AddSectionButton
                    applicableSectionTypes={applicableSectionTypesAfter}
                    newOrder={order + 1}
                  />
                )}
              </WithRequiredHasuraRoles>
            )}
          </>
        )}
      </Flex>
      {confirmationDialog}
    </>
  );
};
