import { useField } from "formik";
import {
  MustacheEditor,
  MustacheEditorProps,
} from "src/scenes/admin/components/MustacheEditor";
import { CustomFormInput, CustomFormInputProps } from "./CustomFormInput";
import { useEffect } from "react";

type Form = {
  [key: string]: unknown;
};

type Props<FORM extends Form> = {
  name: keyof FORM & string;
  mustacheProps?: MustacheEditorProps;
  initialValidationEnabled?: boolean;
} & Omit<CustomFormInputProps, "children" | "error">;

export function FormMustacheInput<FORM extends Form>({
  mustacheProps,
  initialValidationEnabled,
  ...props
}: Props<FORM>) {
  const { name } = props;
  const [field, meta, helper] = useField(name);

  useEffect(() => {
    if (initialValidationEnabled && field.value && !meta.touched) {
      helper.setTouched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CustomFormInput error={meta.error} {...props}>
      <MustacheEditor
        {...mustacheProps}
        containerProps={{
          height: "3.2rem",
          paddingBottom: "0.15rem",
          borderColor: meta.error ? "red.500" : "gray.200",
          ...mustacheProps?.containerProps,
        }}
        {...field}
        onChange={(value) => {
          helper.setValue(value);
        }}
      />
    </CustomFormInput>
  );
}
