import { Divider, StyleProps } from "@chakra-ui/react";

type Props = {
  orientation?: "vertical" | "horizontal";
};

export function TbDivider({ orientation = "vertical" }: Props) {
  const styles: StyleProps = {};

  if (orientation === "vertical") {
    styles.height = "95%";
    styles.minHeight = "2rem";
  } else {
    styles.width = "95%";
    styles.minWidth = "2rem";
    styles.ml = "6px";
  }

  return (
    <Divider orientation={orientation} borderColor="gray.300" {...styles} />
  );
}
